<nav
  class="navbar navbar-dark bg-dark navbar-style"

>
  <a class="navbar-brand" href="https://qupstyle.co/">
    <img
      src="./././assets/QUP-signup.png"
      style="width: 80px; margin-top: -8px"
      alt=""
    />
  </a>
  <ul class="nav justify-content-end" *ngIf="UserLoggedIn == 'Yes'">
    <li class="nav-item">
      <p
        class="nav-link linkStyle"
        style="
          z-index: 1500;
          opacity: 1;
          cursor: pointer;
          margin-top: 4px;
        "
      >
        <a
          href="https://qupstyle.co/"
          style="text-decoration: none; color: #fff"
          >Home</a
        >
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link linkStyle"
        style="
          z-index: 1500;
          opacity: 1;
          cursor: pointer;
          margin-top: 4px;
        "
      >
        <a
          href="https://qupstyle.co/about/"
          style="text-decoration: none; color: #fff"
          >About</a
        >
      </p>
    </li>
    <li>
      <button
      mat-flat-button
      [matMenuTriggerFor]="menu1"
      class="button-profile"
      (click)="getUpdatedUserFirstNameLastName()"
      *ngIf="UserLoggedIn == 'Yes'"
      style="background: transparent"
    >
      <img
        id="navBarProfileImage"
        src="{{ profileData?.profileImageURL }}"
        style="
          height: 40px;
          width: 40px;
          border-radius: 50px;
          margin-right: 10px;
          object-fit: cover;
        "
        *ngIf="profileData?.profileImageURL"
      />
      <img
        id="navBarProfileImage"
        src="../../../assets/images/grey.jpg"
        style="
          height: 40px;
          width: 40px;
          border-radius: 50px;
          margin-right: 10px;
          object-fit: cover;
        "
        *ngIf="!profileData?.profileImageURL"
      />
      <!-- <mat-icon> arrow_drop_down </mat-icon> -->
    </button>
    <mat-menu #menu1="matMenu">
      <mat-card
        style="
          background-color: #333333;
          color: #fff;
          border-radius: 20px 20px 0px 0px;
          margin-top: -8px;
        "
      >
        <span style="padding: 10px; text-align: center">{{
          firstNameLastName
        }}</span>
      </mat-card>
      <button mat-menu-item (click)="goTODashboard()">
        <mat-icon> dashboard </mat-icon>Dashboard
      </button>
      <button mat-menu-item (click)="goTOProfile()">
        <mat-icon> account_circle </mat-icon>My Profile
      </button>
      <button mat-menu-item (click)="goToupdateprofile()">
        <mat-icon> edit </mat-icon>Update My Info
      </button>
      <button mat-menu-item (click)="goTOSettings()">
        <mat-icon> settings </mat-icon>Settings
      </button>
      <!-- <button mat-menu-item (click)="blockedUser()">
        <mat-icon> blocked </mat-icon>Blocked Users
      </button> -->
      <button mat-menu-item (click)="logout()">
        <mat-icon> logout </mat-icon>Logout
      </button>
    </mat-menu>
    </li>
  </ul>


  <ul class="nav justify-content-end linkStyle-1" *ngIf="UserLoggedIn == 'No'">
    <li class="nav-item">
      <p
        class="nav-link menu-text"
        style="
          z-index: 1500;
          opacity: 1;
          cursor: pointer;
          margin-top: 4px;
        "
      >
        <a
          href="https://qupstyle.co/"
          style="text-decoration: none; color: #fff"
          >Home</a
        >
      </p>
    </li>
    <li class="nav-item">
      <p
        class="nav-link menu-text"
        style="
          z-index: 1500;
          opacity: 1;
          cursor: pointer;
          margin-top: 4px;
        "
      >
        <a
          href="https://qupstyle.co/about/"
          style="text-decoration: none; color: #fff"
          >About</a
        >
      </p>
    </li>
    <li class="nav-item">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="login-btn"


      >
        Log In
        <span style="float: right"><mat-icon>arrow_drop_down</mat-icon></span>
      </button>
      <mat-menu #menu="matMenu" style="margin-top: 60px">
        <button mat-menu-item>
          <a
            href="{{ customerBaseUrl }}/s/pages/"
            style="text-decoration: none; color: black"
            >Customer Log In</a
          >
        </button>
        <button mat-menu-item>
          <a
            href="{{ businessBaseUrl }}"
            style="text-decoration: none; color: black"
            >Provider Log In</a
          >
        </button>
      </mat-menu>
    </li>
    <li class="nav-item">
      <button
        *ngIf="UserLoggedIn == 'No'"
        class="btn btn-style-1"
        [matMenuTriggerFor]="menu1"
        type="button"
      >
        Sign Up
        <span style="float: right"><mat-icon>arrow_drop_down</mat-icon></span>
      </button>
      <mat-menu #menu1="matMenu" style="margin-top: 60px">
        <button mat-menu-item>
          <a
          href="{{ customerBaseUrl }}/register"
            style="text-decoration: none; color: black"
            >Customer Signup</a
          >
        </button>
        <button mat-menu-item>
          <a
            href="{{ businessBaseUrl }}/register"
            style="text-decoration: none; color: black"
            >Provider Signup</a
          >
        </button>
      </mat-menu>
    </li>
  </ul>
</nav>
<div class="container-fulid">
  <video
    muted
    autoplay
    class="video-container"
    onloadedmetadata="this.muted = true"
    oncanplay="this.play()"
  >
    <source [src]="displayURL" type="video/mp4" />
  </video>

  <img class="image-conatiner" src="./././assets/images/qup-pic.jpg">
  <div class="centered">
    <div class="row">
      <div class="col-md-12">
        <form
          [formGroup]="searchForm"
          (ngSubmit)="searchResult()"
        >
          <mat-form-field
            class="example-form-field custom-field"
            appearance="outline"
            class="field-size"
          >
            <input
              matInput
              type="text"
              formControlName="searchText"
              placeholder="I am searching for ..."
              style="font-weight: 800; color: #000 !important"
            />
            <button matSuffix mat-icon-button type="submit" aria-label="Clear">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #callLogoutDialog>
  <form
    #userForm="ngForm"
    style="width: 400px; height: 200px; border-radius: 10px"
  >
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Logout Confirmation!</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <p style="text-align: center; padding: 20px; font: 400 20px;">
      Are you sure you want to logout ?
    </p>

    <mat-dialog-actions align="center">
      <button
        mat-raised-button
        type="button"
        matDialogClose="no"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        No
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="yes"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Yes
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
