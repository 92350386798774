import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { CoreService } from 'src/app/_services/core.services';
import { DynamicSearchService } from 'src/app/_services/dynamic-search.services';
import { UpdateProfileService } from 'src/app/_services/update-profile.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-global-search-page',
  templateUrl: './global-search-page.component.html',
  styleUrl: './global-search-page.component.css',
})
export class GlobalSearchPageComponent {
  @ViewChild('callLogoutDialog')
  callLogoutDialog: TemplateRef<any>;
  @ViewChild('searchDialog')
  searchDialog: TemplateRef<any>;

  userInfo: any;
  filteredValue: any = '';
  id: string;
  profileData: any;
  imageUrl: string;
  profileImageURL: any;
  notificationData: any;
  filterCount: any;
  newfilterCount: any;
  globalResult: any;
  UserLoggedIn: any;
  firstNameLastName: any;
  displayURL: any;
  customerBaseUrl: string;
  businessBaseUrl: string;
  isCollapsed = false;

  searchForm: FormGroup;
  readonly widthBreakpoint: number = 600;
  screenWidth: any;
  constructor(
    private readonly keycloak: KeycloakService,
    private router: Router,
    public dialog: MatDialog,
    private coreService: CoreService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private updateProfileService: UpdateProfileService,
    private dynamicSearchService: DynamicSearchService,
    private sanitizer: DomSanitizer
  ) {
    this.screenWidth = window.innerWidth;
    let that = this;
    that.displayURL = that.sanitizer.bypassSecurityTrustResourceUrl(
      'https://d37or6kvo8494u.cloudfront.net/SearchPage/Background-Video/qup-video.mp4'
    );
  }

  ngOnInit() {
    this.customerBaseUrl = environment.customerBaseUrl;
    this.businessBaseUrl = environment.businessBaseUrl;

    this.mainForm();
    this.userInfo = this.coreService.getUserDetails();
    // this.getDetails();
    if (this.keycloak.isLoggedIn() == true) {
      this.UserLoggedIn = 'Yes';
      this.getDetails();
    } else {
      this.UserLoggedIn = 'No';
    }

    if (this.profileData?.data && this.profileData?.data.length > 0) {
      this.userService.imageUrl$.subscribe((url) => {
        this.profileData['profileImageURL'] = url;
      });
    }
  }

  mainForm() {
    this.searchForm = this.formBuilder.group({
      searchText: [''],
    });
  }

  public logout() {
    let dialogRef = this.dialog.open(this.callLogoutDialog, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        // this.keycloak.clearToken();
        this.router.navigate([this.customerBaseUrl]);
        this.keycloak.logout(this.customerBaseUrl);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  searchResult() {
    this.router.navigate(['/search-results', this.searchForm.value.searchText]);
  }

  dashboard() {
    this.router.navigateByUrl('s/pages/dashboard');
  }

  goTOProfile() {
    this.router.navigateByUrl('/s/pages/personal-profile');
  }

  goToupdateprofile() {
    this.router.navigateByUrl('s/pages/update-profile');
  }

  goTOUpdateProfile() {
    this.router.navigateByUrl('s/pages/update-profile');
  }

  goTODashboard() {
    this.router.navigateByUrl('s/pages/dashboard');
  }

  blockedUser() {
    this.router.navigateByUrl('s/pages/blocked-users');
  }

  goTOSettings() {
    this.router.navigateByUrl('s/pages/settings');
  }

  getDetails() {
    this.id = this.coreService.getCustomerId()?.[0];
    this.updateProfileService.getProfileDetails(this.id).subscribe((data) => {
      this.profileData = data?.data;
    });
  }

  getUpdatedUserFirstNameLastName() {
    this.updateProfileService.getProfileDetails(this.id).subscribe((data) => {
      let updateFirstNameLastNameData = data?.data;
      this.firstNameLastName =
        updateFirstNameLastNameData.firstName +
        ' ' +
        updateFirstNameLastNameData.lastName;
    });
  }
}
