import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivitiesService } from 'src/app/_services/activity.services';
import { SavedService } from 'src/app/_services/saved.service';
import { CoreService } from './../../_services/core.services';
import { IActivities } from 'src/app/_models/activity.model';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { Router } from '@angular/router';
import { PromotionsService } from 'src/app/_services/promotions.services';
@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrl: './promotions.component.css'
})
export class PromotionsComponent {
 @ViewChild('callReadMorePromotionDetailsDialog')
  callReadMorePromotionDetailsDialog: TemplateRef<any>;

  businessid: string;
  promotions: any[] = [];
  items: any = [];
  listingid: any;
  savedListingNames: any;
  activityListingName: any[] = [];
  activitiesdata: any;
  isloader: any = true;
  isLoading: boolean = false;
  galleryColumns: any;
  promotionDetailsParamValue: any;
  promotionTitleParamValue: any;
  dialogRef: any;

  constructor(private savedService: SavedService,
    public dialog: MatDialog,
    private activitiesService: ActivitiesService,
    private coreService: CoreService,
    private listingInformationService: ListingInformationService,
    private router: Router,
    private promotionsService: PromotionsService,
  ) {}

  ngOnInit() {
    this.getPromotionsData();
    this.getSavedListingNamesDetails();
  }

  getPromotionsData() {
    this.promotions = [];
    this.galleryColumns = [];
    this.promotionsService.getListingPromotion().subscribe((data: { items: IActivities[] }) => {
      this.promotions = data.items.filter(item => item.status !== "Deleted");

      const len = this.promotions?.length || 0;
      this.galleryColumns = this.promotions?.reduce(
        (acc, item, index) => {
          const accIndex = index % 4;
          acc[accIndex].push(item);
          return acc;
        },
        [[], [], [], []]
      );
      console.log('gallery columns data ', this.galleryColumns);
    });
    // this.isloader = true;

  }

  getSavedListingNamesDetails() {
    this.savedService.getFavorite().subscribe((data) => {
      if (data?.items && data?.items.length > 0) {
        this.savedListingNames = data?.items;
        console.log(this.savedListingNames, 'saved data');
      }
    });
  }

  getPromotionsByListingid() {
    this.promotions = [];
    this.promotionsService.getPromotionByListingId(this.listingid).subscribe((data) => {
      if (data.items && data.items.length) {
        this.promotions = data.items.filter((item: any) => item.status !== "Deleted");
        const len = this.promotions?.length || 0;
        this.galleryColumns = this.promotions?.reduce(
          (acc, item, index) => {
            const accIndex = index % 4;
            acc[accIndex].push(item);
            return acc;
          },
          [[], [], [], []]
        );
      }
    })
  }
  
  findPromotionByListing(e: any) {
    this.listingid = e.value;
    this.getPromotionsByListingid();
  }

  ReadMorePromotionDetailsDialog(promotionDetailsParam: any, promotionTitleParam: any) {
    this.promotionDetailsParamValue = promotionDetailsParam.replace(/\n/g, '<br/>');;
    this.promotionTitleParamValue = promotionTitleParam;
    this.dialogRef = this.dialog.open(this.callReadMorePromotionDetailsDialog,
      {
        height: '70vh',
        width: '50vw',
        disableClose: true
      });
  }
  showAll()
  {
    this.getPromotionsData();
  }
 
}
