import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicSearchService } from 'src/app/_services/dynamic-search.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.css',
})
export class SearchResultsComponent {
  listings: any;
  searchValue: any;
  searchText: any = '';

  constructor(
    private listingInformationService: ListingInformationService,
    private activatedRoute: ActivatedRoute,
    private dynamicSearchService: DynamicSearchService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.searchValue = params['value'];
      this.getSearchData();
    });
    // this.getListings();
  }

  getSearchData() {
    this.dynamicSearchService
      .createSearch(this.searchValue)
      .subscribe((data) => {
        this.getListings(data);
      });
  }

  // searchMessage(event: Event){
  //   let filteredValue = (event.target as HTMLInputElement).value;
  //   this.searchValue = filteredValue.trim().toLowerCase();
  // }

  // searchData(){
  //   this.getListings();
  //   this.router.navigateByUrl('/search-results');
  // }

  getListings(data: any) {
    // this.listingInformationService.getAllListings().subscribe((data)=>{
    // this.listings = data?.list;
    let sortedDataList = data?.list.map((element: any) => {
      if (element.cityName && this.isValidJSON(element.cityName)) {
        element.cityName = JSON.parse(element.cityName).name;
      }
      if (element.state && this.isValidJSON(element.state)) {
        element.state = JSON.parse(element.state).name;
      }
      if (element.country && this.isValidJSON(element.country)) {
        element.country = JSON.parse(element.country).name;
      }
      return element;
    });
    this.listings = sortedDataList;
    // });
  }
  isValidJSON(str: any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  // get filteredDate(){
  //   return this.listingInformationService.searchGlobally((this.listings || []), this.searchValue || '');
  // }
}
