import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { ResponseModal } from '../_models/response-modal';
import { IGallery } from '../_models/gallery.model';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  constructor(private httpClient: HttpClient) {}

  getBusinessGallery() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/gallery/images`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }


  getListingGalleryById(listingId: string){
    return this.httpClient
    .get<any>(
      `${environment.apiUrl}/publicUri/gallery/provider/listing?listingId=${listingId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getGalleryData(customerId:string) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/gallery/images`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  addFeatureImage(imageId: string, featured: boolean): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/gallery/provider/update/featured?imageId=${imageId}&featured=${featured}` , { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
}

  getListingGallery(listingId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/gallery/customer/${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getGalleryByListingId(listingId: string){
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/gallery/provider/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  // updateRequest(requestId: string, status: string): Observable<any> {
  //   const headers = { 'content-type': 'application/json' };
  //   return this.httpClient
  //     .put<any>(`${environment.apiUrl}/tagged/update/request?Id=${requestId}&tagRequestStatus=${status}` , { headers })
  //     .pipe(
  //       catchError((err) => {
  //         console.error(err);
  //         throw err;
  //       })
  //     );
  //   }

  updateRequest(imageId: string, taggedId: string, status: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/gallery/update/tag-requests?imageId=${imageId}&taggedId=${taggedId}&tagStatus=${status}` , { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
    }

    getTaggedPost(){
      return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/publicUri/gallery/tagged-posts?tagStatus=approved`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
    }

    getTaggedPostbyId(listingId: any){
      return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/publicUri/gallery/tagged-posts/listingId?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
    }

  getTaggedRequest(){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/publicUri/gallery/tagged-posts?tagStatus=pending`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getImageById() {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/gallery/images`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  // getImageById(galleryId: string) {
  //   return this.httpClient
  //     .get<ResponseModal>(
  //       `${environment.apiUrl}/gallery/find/image/${galleryId}`
  //     )
  //     .pipe(
  //       map((response) => {
  //         return response || {};
  //       }),
  //       catchError(this.errorMgmt)
  //     );
  // }

  uploadGallery(
    businessId: string,
    // fileName: string,
    category: string,
    file: File,
    galleryDescription: string,
    taggedMembers: string,
    featured: any
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    // formData.append('fileName', fileName);
    formData.append('customerId', businessId);
    formData.append('category', category);
    formData.append('taggedMembers', taggedMembers);
    formData.append('galleryDescription', galleryDescription);
    formData.append('featured', featured);


    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/gallery/create/image`,
      formData
    );

    return this.httpClient.request(req);
  }

  updateGallery(
    galleryId: string,
    businessId: string,
    fileName: string,
    category: string,
    file: File,
    galleryDescription: string,
    taggedMembers: string,
    featured: any

  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('id', galleryId);
    formData.append('customerId', businessId);
    formData.append('category', category);
    formData.append('taggedMembers', taggedMembers);
    formData.append('galleryDescription', galleryDescription);
    formData.append('featured', featured);


    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/gallery/update/image`,
      formData
    );

    return this.httpClient.request(req);
  }

  updateGalleryDetails(galleryInfo: IGallery): Observable<IGallery> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/providergallery`, galleryInfo, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteGallery(galleryid: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/gallery/image/${galleryid}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  likeImage(

    imageId: string,
    isLiked: any,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('imageId', imageId);
    formData.append('isLiked', isLiked);

    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/gallery/image/likes`,
      formData
    );

    return this.httpClient.request(req);
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
