import { Component, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import {
  BehaviorSubject,
  debounceTime,
  fromEvent,
  Subject,
  Subscription,
} from 'rxjs';
import { CoreService } from 'src/app/_services/core.services';
import { NotificationsService } from 'src/app/_services/notifications.services';
import { SettingsService } from 'src/app/_services/settings.service';
import { UpdateProfileService } from 'src/app/_services/update-profile.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from '../../../environments/environment';


var SockJs = require('sockjs-client');
var Stomp = require('stompjs');
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent {
  @ViewChild('callLogoutDialog')
  callLogoutDialog: TemplateRef<any>;
  @ViewChild('searchDialog')
  searchDialog: TemplateRef<any>;

  form: FormGroup = new FormGroup({
    tel: new FormControl(''),
  });

  public stompClient: any;
  form1: FormGroup = new FormGroup({
    tel: new FormControl(''),
  });

  searchForm: FormGroup;

  userInfo: any;
  filteredValue: any = '';
  id: string;
  profileData: any;
  openSidebar = true;
  imageUrl: string;
  profileImageURL: any;
  notificationData: any;
  filterCount: any;
  newfilterCount: any;
  firstNameLastName: any;
  customerBaseUrl: string;

  private resizeSub$: Subscription;
  private windowSize: Subject<number> = new BehaviorSubject(0);
  readonly widthBreakpoint: number = 600;
  screenWidth: any;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private readonly keycloak: KeycloakService,
    private coreService: CoreService,
    private settingsService: SettingsService,
    private updateProfileService: UpdateProfileService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2
  ) {
    this.screenWidth = window.innerWidth;
    this.getUpdatedUserFirstNameLastName();
  }

  ngOnInit() {
    this.customerBaseUrl = environment.customerBaseUrl;
    this.mainForm();
    this.userInfo = this.coreService.getUserDetails();
    this.getDetails();
    this.getNotifications();
    this.initializeWebSocket();
    if (this.profileData?.data && this.profileData?.data.length > 0) {
      this.userService.imageUrl$.subscribe((url) => {
        this.profileData['profileImageURL'] = url;
      });
    }
    //  this.settingsService.getIsNav().subscribe(isOpen => {
    // this.openSidebar = isOpen;
    // this.updateSidebarState();
    // });
  }

  ngAfterViewInit() {
    this.resizeSub$ = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe((_) => {
        this.screenWidth = window.innerWidth;
      });
  }

  mainForm() {
    this.searchForm = this.formBuilder.group({
      searchText: [''],
    });
  }

  onClickNav() {
    this.settingsService.toggleNav();
  }

  formatDateTime(date: any) {
    const currentDate = new Date();
    const messageDate = new Date(date);
    const isToday = messageDate.toDateString() === currentDate.toDateString();
    // if (isToday) {
    return messageDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  goTOProfile() {
    this.router.navigateByUrl('/s/pages/personal-profile');
  }

  goTODashboard() {
    this.router.navigateByUrl('/s/pages/dashboard');
  }

  // myActivity() {
  //   this.router.navigateByUrl('/s/pages/my-activity');
  // }
  goTOSettings() {
    this.router.navigateByUrl('/s/pages/settings');
  }
  // goTOBlockedUsers() {
  //   this.router.navigateByUrl('pages/blocked-users');
  // }

  goTOUpdateProfile() {
    this.router.navigateByUrl('s/pages/update-profile');
  }

  public logOutUser() {
    let dialogRef = this.dialog.open(this.callLogoutDialog, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.router.navigate([this.customerBaseUrl]);
        this.keycloak.logout(this.customerBaseUrl);
      } else {
      }
    });
  }

  getDetails() {
    this.id = this.coreService.getCustomerId();
    this.updateProfileService.getProfileDetails(this.id).subscribe((data) => {
      this.profileData = data?.data;
    });
  }

  getNotifications() {
    this.notificationsService.getNotifications().subscribe((data) => {
      if (data && data?.list) {
        this.notificationData = data?.list;
        this.newfilterCount = this.notificationData.filter(
          (e: any) => e.status == 'New'
        );
      }
    });
  }

  deleteNoti(id: any) {
    this.notificationsService.deleteNotification(id).subscribe((data) => {
      this.showSnackbarTopPosition(
        'Notification Deleted successfully',
        'Done',
        '1000'
      );
      this.getNotifications();
      this.initializeWebSocket();
      this.filterCount = this.notificationData.filter(
        (e: any) => e.status == 'New'
      );
    });
  }

  deleteAllNoti(notificationData: any) {
    this.notificationsService
      .deleteAllNotification(notificationData)
      .subscribe((data) => {
        this.showSnackbarTopPosition(
          'Notification Deleted successfully',
          'Done',
          '1000'
        );
        this.initializeWebSocket();
        this.getNotifications();
        this.filterCount = this.notificationData.filter(
          (e: any) => e.status == 'New'
        );
      });
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }

  initializeWebSocket() {
    let myId = this.coreService.getCustomerId();

    let websocketChannel = '/message/' + myId;

    const serverUrl = `${environment.apiUrl}/qup-socket`;
    const ws = new SockJs(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;

    // Listening to web socket event
    this.stompClient.connect({}, function () {
      that.stompClient.subscribe(websocketChannel, (message: any) => {
        if (message?.body === 'Increment') {
          that.getNotifications();
        }
      });
    });
  }

  searchResult() {
    this.router.navigate(['/search-results', this.searchForm.value.searchText]);
  }

  readAll(notificationData: any) {
    this.notificationsService.updateAll(notificationData).subscribe((data) => {
      this.getNotifications();
    });
  }

  markRead(id: any, category: any) {
    this.notificationsService.singleNotiRead(id).subscribe((data) => {
      this.getNotifications();
    });

    if (category == 'Reviews') {
      this.router.navigateByUrl('/s/pages/reviews');
    }
    if (category == 'Gallery') {
      this.router.navigateByUrl('/s/pages/gallery');
    }
    if (category == 'Inbox') {
      this.router.navigateByUrl('/s/pages/inbox');
    }
    if (category == 'messages') {
      this.router.navigateByUrl('/s/pages/inbox');
    }
    if (category == 'GalleryTag') {
      this.router.navigateByUrl('/s/pages/gallery');
    }
  }

  public searchListingDialog() {
    let dialogRef = this.dialog.open(this.searchDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        // this.keycloak.clearToken();
      this.router.navigate([this.customerBaseUrl]);
      this.keycloak.logout(this.customerBaseUrl);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  dashboard() {
    this.router.navigateByUrl('/search');
  }
  getUpdatedUserFirstNameLastName() {
    this.updateProfileService.getProfileDetails(this.id).subscribe((data) => {
      let updateFirstNameLastNameData = data?.data;
      this.firstNameLastName =
        updateFirstNameLastNameData.firstName +
        ' ' +
        updateFirstNameLastNameData.lastName;
    });
  }
}
