import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  constructor(private _formBuilder: FormBuilder, private router: Router) {}
  buildForm() {
    this.loginForm;
    this.loginForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  public validation_messages = {
    emailAddress: [{ type: 'required', message: 'Email address is required' }],
    password: [{ type: 'required', message: 'Password is required' }],
  };

  loginForm = this._formBuilder.group({
    emailAddress: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    password: new FormControl('', Validators.compose([Validators.required])),
  });

  private onValueChanged() {
    if (!this.loginForm) {
      return;
    }
  }

  logIn() {
    this.router.navigateByUrl('/s/pages/dashboard');
  }

  signUp() {
    this.router.navigateByUrl('/signup');
  }
}
