import {
  Directive,
  HostListener,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';
@Directive({
  selector: '[multipleAppDragAndDropImg]',
})
export class MultipleDragAndDropImgDirective {
  constructor() {}
  @HostBinding('class.fileover') fileOver: boolean;
  @HostBinding('style.opacity') private workspace_opacity = '1';
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
    this.workspace_opacity = '0.5';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.workspace_opacity = '1';
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.workspace_opacity = '1';
    let files = evt.dataTransfer!.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
