import { Component, TemplateRef, VERSION, ViewChild } from '@angular/core';
import { ReviewService } from 'src/app/_services/review.services';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReviewEditComponent } from './review-edit/review-edit.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css'],
})
export class ReviewsComponent {
  @ViewChild('callDeleteGalleryDialog')
  callDeleteGalleryDialog: TemplateRef<any>;
  starCount: number = 5;
  starCountOverall: any;
  ratingOverall: any;
  starColor: StarRatingColor = StarRatingColor.accent;
  currentRate = 0;
  businessid: any;
  listingid: string;
  listings: any;
  reviews: any = [];
  selectedTab: string = 'All';
  deleteReviewListingName: any;

  constructor(
    private reviewService: ReviewService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.readReview();
  }

  formatdate(date: any) {
    var utcDate = new Date(date);
    // Get the UTC time in milliseconds
    var utcTime = utcDate.getTime();
    // Calculate the IST time by adding 5 hours and 30 minutes (in milliseconds)
    var istTime = utcTime + 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
    // Create a new date object with the IST time
    var istDate = new Date(istTime);
    return istDate;
  }

  readReview() {
    let listingAddress: any;
    this.reviewService.getReview().subscribe((data) => {
      // this.reviews = data?.list;
      if (data?.list) {
        let sortedDataList = data?.list;
        this.reviews = sortedDataList;

        if (this.reviews) {
          for (let i = 0; i < this.reviews.length; i++) {
            this.reviewService
              .getReviewReply(this.reviews[i].id)
              .subscribe((data) => {
                if (data) {
                  let date1: any;
                  let date2: any;
                  date1 = this.formatdate(data?.data.createdDate);
                  date2 = new Date();
                  var date1WithoutTime = new Date(
                    date1.getFullYear(),
                    date1.getMonth(),
                    date1.getDate()
                  );
                  var date2WithoutTime = new Date(
                    date2.getFullYear(),
                    date2.getMonth(),
                    date2.getDate()
                  );
                  var difference_ms =
                    date2WithoutTime.getTime() - date1WithoutTime.getTime();
                  var difference_days = Math.floor(
                    difference_ms / (1000 * 60 * 60 * 24)
                  );
                  this.reviews[i]['days'] = difference_days;
                }
              });
          }
        }
      }
    });
  }

  readFullReview() {
    this.router.navigateByUrl('/s/pages/create-reviews');
  }

  editReview(id: any, review: any) {
    const dialogRef = this.dialog.open(ReviewEditComponent, {
      data: {
        reviewId: id,
        review,
      },
      height: '95vh',
      width: '80vw',
      disableClose: true,
    });
    dialogRef.componentInstance.reviewUpdated.subscribe(() => {
      this.readReview();
    });
  }

  deleteReview(id: string, reviewListingNameParam: string) {
    this.deleteReviewListingName = reviewListingNameParam;
    let dialogRef = this.dialog.open(this.callDeleteGalleryDialog, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.reviewService.deleteReview(id).subscribe((data) => {
          this.showSnackbarTopPosition(
            'Review deleted successfully',
            'Done',
            '2000'
          );
          this.readReview();
        });
      } else {
        if (result === 'cancel') {
          this.readReview();
        }
      }
    });
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'right', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  changeTab(status: string) {
    this.selectedTab = status;
  }
}
