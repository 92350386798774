import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { RouterComponent } from './components/router/router.component';
import { GlobalSearchPageComponent } from './non-auth-module/global-search-page/global-search-page.component';
import { ContactComponent } from './pages/contact/contact.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ListingBusinessComponent } from './pages/listing-business/listing-business.component';
import { LoginComponent } from './pages/login/login.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { RecentFeedsComponent } from './pages/recent-feeds/recent-feeds.component';
import { ReviewCreateComponent } from './pages/reviews/review-create/review-create.component';
import { ReviewEditComponent } from './pages/reviews/review-edit/review-edit.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { SavedComponent } from './pages/saved/saved.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { SignupComponent } from './shared/signup/signup.component';

const routes: Routes = [
  // { path: '',   redirectTo: '/complete-profile', pathMatch: 'full' },
  // { path: 'complete-profile',   component: CompleteProfileComponent },
  { path: '', redirectTo: '/router', pathMatch: 'full' },
  // { path: 'signin', component: LoginComponent },
  {
    path: 'router',
    component: RouterComponent
  },
  {
    path: '',
    loadChildren: () =>
      import('./non-auth-module/non-auth-module.module').then(
        (m) => m.NonAuthModuleModule
      ),
  },
  {
    path: 's',
    children: [
      {
        path: 'pages',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
      // { path: 'signup', component: SignupComponent },
      // { path: 'forgot-password', component: ForgotPasswordComponent },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'saved',
        component: SavedComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'create-reviews',
        component: ReviewCreateComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'edit-reviews/:id',
        component: ReviewEditComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'listing-businesses',
        component: ListingBusinessComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'signin',
        component: LoginComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'gallery',
        component: GalleryComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },

      {
        path: 'search-results/:value',
        component: SearchResultsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'search-results',
        component: SearchResultsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'promotions',
        component: PromotionsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'recent-feeds',
        component: RecentFeedsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
      {
        path: 'customer/register',
        component: SignupComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
    ],
  },
  { path: '**', component: GlobalSearchPageComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
