import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CoreService {

  constructor(
    protected readonly keycloak: KeycloakService,
    private http: HttpClient
  ) {

  }

  // getOrganizationId() {
  //   let claims = this.keycloak.getKeycloakInstance();
  //   return claims?.tokenParsed?.['customerId'];
  // }

  getOrganizationId() {
    let claims = this.keycloak.getKeycloakInstance();
    return claims.tokenParsed?.['organizationId'];
  }

  getCustomerId() {
    let claims = this.keycloak.getKeycloakInstance();
    return claims.tokenParsed?.['customerId'];
  }

  getUserDetails() {
    /*
     * email, family_name, given_name, name, phone, preferred_username
     */

    let claims = this.keycloak.getKeycloakInstance();
    return {
      email: claims?.tokenParsed?.['email'],
      firstName: claims?.tokenParsed?.['given_name'],
      lastName: claims?.tokenParsed?.['family_name'],
      name: claims?.tokenParsed?.['name'],
      username: claims?.tokenParsed?.['preferred_username'],
      phone: claims?.tokenParsed?.['phone'],
      eulaVersion: claims?.tokenParsed?.['eula-version'],
    };
  }

  getPhone(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/phone-number`).pipe(
      map((d: any) => {
        return d.data.phoneNumber;
      })
    );
  }

  editPhone(newPhn: string) {
    return this.http
      .post(`${environment.apiUrl}/users/update-phone`, { phoneNumber: newPhn })
      .pipe(
        map((d: any) => {
          return d.result;
        })
      );
  }

  changePassword(passwords: any) {
    return this.http.post(
      `${environment.apiUrl}/users/change-password`,
      passwords
    );
  }

  isCustomer() {
    return this.keycloak.isUserInRole('customer');
  }
}
