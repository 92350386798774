<div class="container">
  <div class="row" style="padding: 20px; padding-bottom: 0px;">
    <div class="col-sm-6">
  <h1 style="font-weight: 700; color: #333333;">Tagged Members <span style="font-size: smaller; color: grey; font-weight: 500;"></span></h1>
</div>
    <div class="col-sm-6">
      <button mat-mini-fab mat-dialog-close style="float: right; background-color: #fff; color: #333333;" (click)="closeDialog()">
        <span class="material-icons">
          cancel
          </span>
      </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div *ngFor="let taggedMember of filterData?.taggedMembers" style="padding: 15px;">
    <div class="row">
      <div class="col-md-2"  style="display: flex; align-items: center;">
        <img src="{{taggedMember?.taggedUserProfileUrl}}" style="width: 60px; height: 60px; border-radius: 10px;">
      </div>
      <div class="col-md-3"  style="display: flex; align-items: center;">
       <p style="margin-bottom: 0px;">{{taggedMember?.taggedUserName}}</p>
      </div>
      <div class="col-md-3"  style="display: flex; align-items: center;">
        <mat-chip style="margin-bottom: 0px;">{{taggedMember?.taggedUserType}}</mat-chip>
       </div>
       <div class="col-md-2"  style="display: flex; align-items: center;">
        <button
        *ngIf="taggedMember?.tagStatus == 'denied'"
        mat-flat-button
        style="background-color: #ffe2e2; color: #ec5858; border-radius: 10px; text-align: center; margin-top: -5px;"
      >
       Denied
      </button>
        <button
        *ngIf="taggedMember?.tagStatus == 'Pending'"
        mat-flat-button
        style="background-color: #ffedd8; color: #f6af2a; border-radius: 10px; text-align: center; margin-top: -5px;"
      >
        Pending
      </button>
      <button
      *ngIf="taggedMember?.tagStatus == 'Approved'"
      mat-flat-button
      style="background-color: #dcffed; color: #2cb349; border-radius: 10px; text-align: center; margin-top: -5px;"
    >
     Approved
    </button>
       </div>
      <div class="col-md-2" >
        <button
        *ngIf="taggedMember?.tagStatus !== 'denied'"
              type="button"
              mat-icon-button
              style="color: #ec5858; float: right"
              (click)="deniedTagRequest(filterData.id, taggedMember.taggedUserId)"

            >
              <span class="material-icons-outlined"> cancel </span>
            </button>
            <button
            *ngIf="taggedMember?.tagStatus == 'denied'"
            type="button"
            mat-icon-button
            style="color: #ec58586c; float: right"
            disabled
          >
            <span class="material-icons-outlined"> cancel </span>
          </button>
      </div>
      <span class="my-1">
      <mat-divider></mat-divider>
    </span>
    </div>
  </div>
  </div>


  <ng-template #callDenyRequestDialog>
    <form #userForm="ngForm">
      <p matDialogTitle style="text-align: left; font-size: 15px; margin-top:-10px"><b>Deny Confirmation !</b></p>
    <mat-divider style="margin-top:-20px"></mat-divider>
        <mat-dialog-content>
        <p style="text-align: center; padding: 30px;font: 400 20px;">
        Are you sure you want to Deny?
      </mat-dialog-content>
      <mat-dialog-actions align="center" class="mb-2">
        <button mat-raised-button type="button" matDialogClose="no" style="border-radius: 10px; border: 2px; width: 100px;">No</button>
        <button
          class="btn-primary"
          type="button"
          mat-raised-button
          matDialogClose="yes"
          style="background-color: #525EB1 ; border-radius: 10px; color: #fff; width: 100px;"
        >
          YES
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>
