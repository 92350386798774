import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../material-imports';
import { FormsModule } from '@angular/forms';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { OverallStarComponent } from './overall-star/overall-star.component';
import { SubmitReviewRatingComponent } from './submit-review-rating/submit-review-rating.component';
import { ReviewOverallStarComponent } from './review-overall-star/review-overall-star.component';
import { GalleryLightboxComponent } from './gallery-lightbox/gallery-lightbox.component';
import { TaggedImageLightboxComponent } from './tagged-image-lightbox/tagged-image-lightbox.component';
import { LineBreakPipe } from './lineBreak.pipe';
import { SignupComponent } from './signup/signup.component';
import { MyProfileGalleryComponent } from './my-profile-gallery/my-profile-gallery.component';

@NgModule({
  declarations: [NavBarComponent, SideBarComponent, StarRatingComponent, OverallStarComponent, SubmitReviewRatingComponent,ReviewOverallStarComponent,GalleryLightboxComponent,TaggedImageLightboxComponent,LineBreakPipe,SignupComponent,MyProfileGalleryComponent ],
  imports: [CommonModule, RouterModule, AngularMaterialModule, FormsModule],
  exports: [NavBarComponent, SideBarComponent, StarRatingComponent, OverallStarComponent, SubmitReviewRatingComponent, ReviewOverallStarComponent,GalleryLightboxComponent, TaggedImageLightboxComponent,LineBreakPipe,SignupComponent,MyProfileGalleryComponent ],
})
export class SharedModule {}
