<nav
  style="background-color: white; z-index: 1000"
  class="navbar navbar-expand-lg sticky-top"
>
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      (click)="onClickNav()"
    >

      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="logo"  (click)="dashboard()">
      <img src="./././assets/QUP-dashboard.png" style="width: 70px; cursor: pointer;"  (click)="dashboard()" />
    </div>
    <div class="col-lg-12">
    <div class="row" style=" display: flex; align-items: center">
      <div class="col-lg-6">
      <form class="displaySearch" [formGroup]="searchForm"  (ngSubmit)="searchResult()"  *ngIf="screenWidth > 600">
        <mat-form-field class="example-form-field custom-field " appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput type="text" formControlName="searchText">
            <button matSuffix mat-icon-button type="submit" aria-label="Clear">
              <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
      </form>
      </div>
      <div class="col-lg-3  displaySearchbtn" *ngIf="screenWidth <= 600">
          <button  (click)="searchListingDialog()" style="width: 40px; height: 40px; font-size:larger; background-color: white; border: 0px;"><mat-icon>search</mat-icon></button>
      </div>

      <div class="col-lg-3 noti">
        <button [matMenuTriggerFor]="belowMenu" style="width: 40px; height: 40px; font-size:larger; background-color: white; border: 0px;">
          <mat-icon class="material-icons-outlined" matBadge="{{newfilterCount?.length}}" matBadgeColor="warn">notifications</mat-icon></button>
          	<mat-menu #belowMenu="matMenu" xPosition="before" yPosition="below" style="border-radius: 20px;" class="custom-width">

      <div class="row" style="margin: 10px; background-color: #525EB1; display: flex; align-items: center; border-radius: 5px;">
        <div class="col-md-9">
          <h2 style="color: #fff; margin-top: 15px;">Notifications</h2>
        </div>
        <div class="col-md-3 button-shift" style="display: flex; align-items: center;" *ngIf="notificationData?.length > 0">
        <div class="example-button-container" (click)="$event.stopPropagation();">
          <button mat-mini-fab  style="width: 30px; height: 30px; font-size: smaller; color: #2cb349; background-color: #fff;" (click)="readAll(notificationData)">
            <mat-icon><span class="material-icons">
              done_all
              </span></mat-icon></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div class="example-button-container" (click)="$event.stopPropagation();">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button mat-mini-fab  style="width: 30px; height: 30px; font-size: smaller; color:#ec5858; background-color: #fff;" (click)="deleteAllNoti(notificationData)">
          <mat-icon >delete</mat-icon></button>
        </div>
        </div>
        <div class="col-md-3 icon-style" style="display: flex; align-items: center;" *ngIf="notificationData?.length <= 0">
        <div class="example-button-container" (click)="$event.stopPropagation();">
          <button mat-mini-fab disabled  style="width: 30px; height: 30px; font-size: smaller; color: #2cb349;">
          <mat-icon><span class="material-icons">
            done_all
            </span></mat-icon></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div class="example-button-container" (click)="$event.stopPropagation();">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button mat-mini-fab disabled style="width: 30px; height: 30px; font-size: smaller; color:#ec5858;" (click)="deleteAllNoti(notificationData)">
          <mat-icon >delete</mat-icon></button>
        </div>
        </div>
      </div>
      <div class="scroll">
      <div *ngIf="notificationData?.length > 0" >
<div *ngFor="let notification of notificationData" style="margin: 10px; width: 480px; cursor: pointer;">
  <mat-card class="notification-card" style="background-color: rgb(241, 240, 240);" *ngIf="notification.status == 'New'">
    <div class="row" (click)="markRead(notification.id, notification.category)">
      <div class="col-md-10">
  <p style="font-size: small;"><b>{{notification.message}}</b></p>
    <p style="font-size: small;"><b>{{notification.notificationTime | date }} {{ formatDateTime(notification.notificationTime) }}</b></p>
</div>
<div class="col-md-2 del-notification" (click)="$event.stopPropagation();">
<mat-icon (click)="deleteNoti(notification.id)" style="cursor: pointer; margin-top: 5px; font-size: smaller; float: right;">close</mat-icon>
</div>
<div>
<button mat-mini-fab style="width: 10px; height: 10px; border-radius: 50px; background-color: #2cb349; float: right; margin-top: -20px; margin-right: 10px;" *ngIf="notification.status == 'New'"></button>
</div>
</div>
  </mat-card>
  <mat-card class="notification-card" style="background-color: #fff;" *ngIf="notification.status == 'Seen'" >
    <div class="row" (click)="markRead(notification.id, notification.category)">
      <div class="col-md-10">
  <p style="font-size: small;">{{notification.message}}</p>
    <p style="font-size: small;" >{{notification.notificationTime | date }} {{ formatDateTime(notification.notificationTime) }}</p>
</div>
<div class="col-md-2 del-notification" (click)="$event.stopPropagation();">
<mat-icon (click)="deleteNoti(notification.id)" style="cursor: pointer; margin-top: 5px; font-size: smaller; float: right;">close</mat-icon>
</div>
<div>
<button mat-mini-fab style="width: 10px; height: 10px; border-radius: 50px; background-color: #2cb349; float: right; margin-top: -20px; margin-right: 10px;" *ngIf="notification.status == 'New'"></button>
</div>
</div>
  </mat-card>
</div>
</div>
<div *ngIf="notificationData?.length <= 0">
  <div style="margin: 10px; width: 500px;">
    <mat-card class="notification-card" style="padding: 10px;">
  <div class="row" >
    <div class="col-md-12" (click)="$event.stopPropagation();">
  <p style="font-size: small; text-align: center;">You don't have any notification.</p>
  </div>
  </div>
</mat-card>
</div>
</div>
</div>
</mat-menu>

      </div>
      <div class="col-lg-2 user">
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li
              class="nav-item"
            >
              <button mat-flat-button [matMenuTriggerFor]="menu" class="button-profile" (click)="getUpdatedUserFirstNameLastName()">
                <img id="navBarProfileImage" src="{{profileData?.profileImageURL}}" style="height: 40px; width: 40px; border-radius: 50px; margin-right:10px; object-fit: cover;" *ngIf="profileData?.profileImageURL">
                <img id="navBarProfileImage" src="../../../assets/images/grey.jpg" style="height: 40px; width: 40px; border-radius: 50px; margin-right:10px; object-fit: cover;" *ngIf="!profileData?.profileImageURL">
                <!-- <mat-icon> arrow_drop_down </mat-icon> -->
              </button>
              <mat-menu #menu="matMenu">
                <mat-card style="background-color: #333333; color: #fff; border-radius: 20px 20px 0px 0px; margin-top: -5px;">
                  <span style="padding: 10px; text-align: center;">{{ firstNameLastName }}</span>
                </mat-card>
                <button mat-menu-item (click)="goTODashboard()">
                  <mat-icon> dashboard </mat-icon>Dashboard
                </button>
                <button mat-menu-item (click)="goTOProfile()">
                  <mat-icon> account_circle </mat-icon>My Profile
                </button>

                <button mat-menu-item (click)="goTOUpdateProfile()">
                  <mat-icon> edit </mat-icon>Update My Profile
                </button>
                <button mat-menu-item (click)="goTOSettings()">
                  <mat-icon> settings </mat-icon>Settings
                </button>
                <!-- <button mat-menu-item (click)="goTOBlockedUsers()">
                  <mat-icon> blocked </mat-icon>Blocked Users
                </button> -->
                <button mat-menu-item (click)="logOutUser()">
                  <mat-icon> logout </mat-icon>Logout
                </button>
              </mat-menu>

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  </div>
</nav>

<ng-template #callLogoutDialog>
  <form #userForm="ngForm" style="width:400px; height: 200px; border-radius:10px">
    <p matDialogTitle style="text-align: left; font-size: 15px; margin-top:-10px"><b>Logout Confirmation!</b></p>
    <mat-divider style="margin-top:-20px"></mat-divider>
    <p style="text-align: center; padding: 20px;font: 400 20px;">
      Are you sure you want to logout ?
    </p>
    <mat-dialog-actions align="center" >
      <button mat-raised-button type="button" matDialogClose="no" style="border-radius: 10px; border: 2px; width: 100px;">No</button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="yes"
        style="background-color: #525EB1; border-radius: 10px; color: #fff; width: 100px;"
      >
        Yes
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #searchDialog>
  <p matDialogTitle style="text-align: left; font-size: 15px; margin-top:-10px"><b>Search Listing</b></p>
  <mat-divider style="margin-top:-20px"></mat-divider>
  <mat-dialog-content>
    <form [formGroup]="searchForm"  (ngSubmit)="searchResult()">
      <mat-form-field class="example-form-field custom-field " appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput type="text" formControlName="searchText">
          <button matSuffix mat-icon-button type="submit" aria-label="Clear" mat-dialog-close>
            <mat-icon>search</mat-icon>
          </button>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="center" class="mb-2">
    <button mat-raised-button type="button" matDialogClose="cancel" style="border-radius: 10px; border: 2px; width: 100px;">Cancel</button>
    <button
      class="btn-primary"
      type="button"
      mat-raised-button
      matDialogClose="delete"
      style="background-color: #525EB1 ; border-radius: 10px; color: #fff; width: 100px;"
    >
      Delete
    </button>
  </mat-dialog-actions> -->
</ng-template>
