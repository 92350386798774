import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivitiesService } from 'src/app/_services/activity.services';
import { SavedService } from 'src/app/_services/saved.service';
import { CoreService } from './../../_services/core.services';
import { IActivities } from 'src/app/_models/activity.model';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PromotionsService } from 'src/app/_services/promotions.services';
import { RecentFeedsDateFilterDialogComponent } from './recent-feeds-date-filter-dialog/recent-feeds-date-filter-dialog.component';

@Component({
  selector: 'app-recent-feeds',
  templateUrl: './recent-feeds.component.html',
  styleUrl: './recent-feeds.component.css'
})
export class RecentFeedsComponent {
  @ViewChild('callDateFilterPopupDialog')
  callDateFilterPopupDialog: TemplateRef<any>;
  @ViewChild('callReadMoreRecentFeedsDialog')
  callReadMoreRecentFeedsDialog: TemplateRef<any>;

  businessid: string;
  recentFeeds: any[] = [];
  items: any = [];
  listingid: any;
  savedListingNames: any;
  activityListingName: any[] = [];
  activitiesdata: any;
  isloader: any = true;
  isLoading: boolean = false;
  recentFeedColumns: any;
  recentFeedsDetailsParamValue: any;
  recentFeedsTitleParamValue: any;
  dialogRef: any;

  constructor(private savedService: SavedService,
    public dialog: MatDialog,
    private activitiesService: ActivitiesService,
    private coreService: CoreService,
    private listingInformationService: ListingInformationService,
    private router: Router,
    private promotionsService: PromotionsService,
  ) {}

  ngOnInit() {
    this.getRecentFeedsData();
    this.getSavedListingNamesDetails();
  }
  
  getRecentFeedsData() {
    this.recentFeeds = [];
    this.recentFeedColumns = [];
    this.activitiesService.getRecentFeedActivities().subscribe((data: { items: IActivities[] }) => {
      this.recentFeeds = data.items.filter(item => item.status !== "Deleted");

      const len = this.recentFeeds?.length || 0;
      this.recentFeedColumns = this.recentFeeds?.reduce(
        (acc, item, index) => {
          const accIndex = index % 4;
          acc[accIndex].push(item);
          return acc;
        },
        [[], [], [], []]
      );
      console.log('recentFeeds columns data ', this.recentFeedColumns);
    });
    // this.isloader = true;

  }

  getSavedListingNamesDetails() {
    this.savedService.getFavorite().subscribe((data) => {
      if (data?.items && data?.items.length > 0) {
        this.savedListingNames = data?.items;
        console.log(this.savedListingNames, 'saved data');
      }
    });
  }

  getRecentFeedsByListingid() {
    this.recentFeeds = [];
    this.activitiesService.getRcentFeedsByListingId(this.listingid).subscribe((data: { items: IActivities[] }) => {
      if (data.items && data.items.length) {
        this.recentFeeds = data.items.filter((item: any) => item.status !== "Deleted");
        const len = this.recentFeeds?.length || 0;
        this.recentFeedColumns = this.recentFeeds?.reduce(
          (acc, item, index) => {
            const accIndex = index % 4;
            acc[accIndex].push(item);
            return acc;
          },
          [[], [], [], []]
        );
      }
    })
  }
  
  findRecentFeedsByListing(e: any) {
    this.listingid = e.value;
    this.getRecentFeedsByListingid();
  }

  showDateFilterPopup() {
    this.dialogRef = this.dialog.open(RecentFeedsDateFilterDialogComponent, { disableClose: true });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const fromDate = result?.fromDate;
        const toDate = result?.toDate;
        this.recentFeeds = [];
        this.activitiesService.filterRecentFeedsByDate(moment(fromDate,"YYYY-MM-DD").format("YYYY-MM-DD"), moment(toDate,"YYYY-MM-DD").format("YYYY-MM-DD")).subscribe((data: { items: IActivities[] }) => {
          this.recentFeeds = data.items.filter(item => item.status !== "Deleted");
          const len = this.recentFeeds?.length || 0;
          this.recentFeedColumns = this.recentFeeds?.reduce(
            (acc, item, index) => {
              const accIndex = index % 4;
              acc[accIndex].push(item);
              return acc;
            },
            [[], [], [], []]
          );
        });
      }
    });
  }

  ReadMoreRcentFeedsDetailsDialog(promotionDetailsParam: any, promotionTitleParam: any) {
    this.recentFeedsDetailsParamValue = promotionDetailsParam.replace(/\n/g, '<br/>');;
    this.recentFeedsTitleParamValue = promotionTitleParam;
    this.dialogRef = this.dialog.open(this.callReadMoreRecentFeedsDialog,
      {
        height: '70vh',
        width: '50vw',
        disableClose: true
      });
  }
  showAll() {
    this.getRecentFeedsData();
  }
  onClearFilterClick(): void {
    this.getRecentFeedsData();
  }
}
