import { Component, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { CoreService } from 'src/app/_services/core.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { ReviewService } from 'src/app/_services/review.services';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { ReviewEditComponent } from '../review-edit/review-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-review-create',
  templateUrl: './review-create.component.html',
  styleUrls: ['./review-create.component.css'],
})
export class ReviewCreateComponent {
  @ViewChild('callDeleteGalleryDialog')
  callDeleteGalleryDialog: TemplateRef<any>;
  starCount: number = 5;
  starCountOverall: any;
  ratingOverall: any;
  starColor: StarRatingColor = StarRatingColor.accent;

  submitReplyForm: FormGroup;
  formattedReplyContent: string='';
  reviewId: string;
  reviewData: any;
  reviews: any;
  reviewDetails: any;
  businessid: string;
  primary : any;
  alllistings: any;
  listingData: any;
  formattedReviewDescription: string = '';

  constructor(private activatedRoute: ActivatedRoute,
    public reviewService: ReviewService,
    public formBuilder: FormBuilder,
    private listingInformationService: ListingInformationService,
    private coreService: CoreService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    ){
  }

  ngOnInit(){
    this.mainForm();
    this.reviewId = this.activatedRoute.snapshot.params['id'];
    this.businessid = this.coreService.getCustomerId();
    this.getReviewById();
    this.getReplyDetails();
    // this.getPrimaryList();
  }

  mainForm() {
    this.submitReplyForm = this.formBuilder.group({
      replyContent: [''],
  });
}

getReviewById(){
  this.reviewService.reviewById(this.reviewId).subscribe((data)=>{
    this.reviewData = data?.data;
    if (this.reviewData?.reviewDescription) {
      this.formattedReviewDescription = this.reviewData.reviewDescription.replace(/\n/g, '<br/>');
    }
    // this.formattedReviewDescription=this.reviewData?.reviewDescription.replace(/\n/g, '<br/>');
    this.getAllListings(this.reviewData.reviewToListingId);
  })
}

getAllListings(reviewToListingId: any){
  this.listingInformationService.getallListings().subscribe((data)=>{
    if (data?.items && data?.items.length > 0)
      {
        let sortedDataList = data?.items;
        //   let sortedDataList = data?.items.map((element:any) => {

        // if(element.cityName && this.isValidJSON(element.cityName)){

        //   element.cityName = JSON.parse(element.cityName).name
        // }
        // if(element.state && this.isValidJSON(element.state)){
        //   element.state = JSON.parse(element.state).name
        // }
        // if(element.country && this.isValidJSON(element.country)){
        //   element.country = JSON.parse(element.country).name
        // }
        // return element;
        // });
        this.alllistings = sortedDataList;
        this.listingData = this.alllistings?.find((r: any) => r.id === reviewToListingId);
      }
  });
}
  isValidJSON(str:any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  onReplySubmit(){
    let obj = {
      "replyContent": this.submitReplyForm.value.replyContent,
      "reviewId": this.reviewId,
      "status": "Active"
    }
    this.reviewService.sendReviewReply(obj).subscribe((data)=>{
      this.getReplyDetails();
      this.submitReplyForm.reset(); // Reset form after submission
    })
  }

  getReplyDetails(){
    this.reviewService.getReviewReply(this.reviewId).subscribe((data)=>{
      this.reviewDetails = data?.data;
      if (this.reviewDetails?.replyContent) {
        this.formattedReplyContent = this.reviewDetails.replyContent.replace(/\n/g, '<br/>');
      }
      // this.formattedReplyContent = this.reviewDetails?.replyContent.replace(/\n/g, '<br/>');
    })
  }

  editReview(id: any, review: any){
    const dialogRef = this.dialog.open(ReviewEditComponent, {
      data: {
        reviewId: id,
        review
      },
      height: '95vh',
      width: '80vw',
    });
    dialogRef.componentInstance.reviewUpdated.subscribe(() => {
      this.getReviewById();
      dialogRef.close();
    });
}

// deleteReview(){
//   this.reviewService.deleteReview().subscribe((data)=>{

//   })

// }

deleteReview(id: string) {
  let dialogRef = this.dialog.open(this.callDeleteGalleryDialog, { disableClose: true });
  dialogRef.afterClosed().subscribe((result) => {
    if (result === 'delete') {
      this.reviewService.deleteReview(id).subscribe((data) => {
        this.getReviewById();
        this.showSnackbarTopPosition('Review deleted successfully','Done','2000');
        this.router.navigateByUrl('pages/reviews')
      });
    } else {
      if (result === 'cancel') {
        this.getReviewById();
      }
    }
  });
}

showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
  this.snackBar.open(content, action, {
    duration: 2000,
    verticalPosition: "top", // Allowed values are  'top' | 'bottom'
    horizontalPosition: "right" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
  });
}

}
