<div class="m-4">
  <div class="card-orview">
    <button
      class="routerLink"
      [routerLink]="['/s/pages/dashboard']"
      routerLinkActive="active"
    >
      <mat-icon>chevron_left</mat-icon>
      <h1 class="mb-0" style="font-weight: 500; margin-top: -0.8%">
        Recent Feeds
      </h1>
    </button>
  </div>
  <div class="row align-middle mt-3">
    <div class="col-md-10">
      <mat-form-field
        class="field-style custom-field example-full-width"
        style="float: left"
        appearance="outline"
      >
        <mat-select
          (selectionChange)="findRecentFeedsByListing($event)"
          placeholder="All Business Listings"
        >
          <mat-option (click)="showAll()">All Business Listings</mat-option>
          <mat-option
            *ngFor="let list of savedListingNames"
            [value]="list.savedListingId"
            >{{ list.listingName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 filter" style="display: flex; align-items: center">
      <div style="margin-right: 20px">
        <span (click)="showDateFilterPopup()" class="mx-2 icon">Filter</span>
        <span (click)="showDateFilterPopup()" class="material-icons style">
          filter_list
        </span>
      </div>
      <div>
        <span class="mx-2 icon" (click)="onClearFilterClick()"
          >Clear&nbsp;Filter</span
        >
        <span class="material-icons style" (click)="onClearFilterClick()">
          filter_list_off
        </span>
      </div>
    </div>
  </div>
  <div>
    <div class="row" *ngIf="recentFeedColumns">
      <ng-container *ngFor="let column of recentFeedColumns">
        <div class="col-lg-3">
          <mat-card
            class="card-style"
            *ngFor="let gall of column; let i = index"
          >
            <img
              src="{{ gall.activityImageURL }}"
              alt="Image"
              class="img-fluid rounded-top"
              *ngIf="gall.activityImageURL"
            />
            <div class="p-3">
              <p>
                <b>{{ gall.title }}</b>
              </p>
              <p style="font-size: 12px; margin-top: -10px">
                {{ gall.activityDate | date : "dd-MMM-yyyy" }}
              </p>
              <p>{{ gall.description }}</p>
              <a
                *ngIf="gall.description"
                (click)="
                  ReadMoreRcentFeedsDetailsDialog(gall.description, gall.title)
                "
                style="cursor: pointer"
              >
                Read more
              </a>
            </div>
            <div class="card-footer">
              <span class="mdc-button__label">
                <img
                  class="image-style"
                  [src]="gall?.listingProfileImageUrl"
                  class="img-fluid"
                  alt="logo"
                  style="height: 30px; width: 30px; border-radius: 50px"
                />
              </span>
              <div
                class="author px-3"
                style="font-size: 12px; margin-left: -10px"
              >
                <b>{{ gall.listingName }}</b>
              </div>
            </div>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #callReadMoreRecentFeedsDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="
        text-align: left;
        font-size: 20px;
        padding: 20px;
        margin-top: -20px;
      "
    >
      <b>{{ recentFeedsTitleParamValue }}</b>
    </p>
    <button
      mat-mini-fab
      mat-dialog-close
      style="
        float: right;
        margin-top: -70px;
        margin-right: 10px;
        background-color: #fff;
        color: #333333;
      "
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-divider style="margin-top: -20px"></mat-divider>

    <mat-dialog-content>
      <p style="padding: 20px; font: 400 20px;">
        {{ recentFeedsDetailsParamValue }}
      </p>
    </mat-dialog-content>
  </form>
</ng-template>
