import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/_services/core.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { SavedService } from 'src/app/_services/saved.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  contactForm: FormGroup
  listingName: any;
  providerId: any;
  userInfo: any;
  providerName: any;
  providerLastname: any;
  currentListing: any;
  listingid: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private listingInformationService: ListingInformationService,
    private coreService: CoreService,
    private savedService: SavedService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
  private data: any,
  ) {}

  ngOnInit(){
    this.mainForm();

    this.userInfo = this.coreService.getUserDetails();
    this.contactForm.patchValue({
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
    })
    this.providerId = this.coreService.getCustomerId();
    // this.getPrimaryListing();
  }

  mainForm() {
    this.contactForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      senderName: [''],
      receiverName: [''],
      subjectContent: [''],
      messageContent: ['']
    });
  }


  onContactFormSubmit(){
    if (this.contactForm.valid) {
      const formData = this.contactForm.value;
      const obj = {
        senderName: this.userInfo.firstName,
        receiverId: this.data?.profileData?.id,
        receiverName: this.data?.profileData?.listingName,
        subjectContent: formData.subjectContent,
        messageContent: formData.messageContent,
        senderId: this.listingid,
      };

      this.savedService.sendMessageTo(obj).subscribe((data) => {
          this.showSnackbarTopPosition('Message Sent successfully', 'Done', '1000');
        },
        (error) => {
          console.error('Error sending message:', error);
          this.showSnackbarTopPosition('Error sending message', 'Close', '2000');
        }
      );
    } else {
      this.showSnackbarTopPosition('Form data is invalid', 'Close', '2000');
    }
  }

  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }

  // getPrimaryListing() {
  //   this.listingInformationService.getPrimaryListing().subscribe((data) => {
  //     console.log(data,'primary')
  //     this.listingName = data.listingName;
  //     this.listingid = data.id;
  //     this.providerName = this.userInfo.firstName
  //     this.providerLastname = this.userInfo.lastName
  //     this.contactForm.patchValue({
  //       firstName: this.providerName,
  //       lastName: this.providerLastname
  //     })

  //     this.currentListing = this.data?.profileData?.listingName
  //   })

  // }
}
