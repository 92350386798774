import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }
// ...
  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
       // redirectUri: window.location.origin + state.url,
        redirectUri: window.location.origin + '/',
      });
    }

    const requiredRoles = route.data['roles'];

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    } else {
      if (requiredRoles.every((role: any) => this.roles.indexOf(role) > -1)) {
        return true;
      }
      else if(this.roles.indexOf('provider') > -1){
        this.router.navigate(['customer/register']);
        return false
      }
      else {
        this.router.navigate(['/403']);
        return false;
      }
    }
  }
}
