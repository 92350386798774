import { Component, OnInit, TemplateRef, VERSION, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoreService } from 'src/app/_services/core.services';
import { SavedService } from 'src/app/_services/saved.service';
import { UserService } from 'src/app/_services/user.service';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { SavedbymeMessageDialogComponent } from './savedbyme-message-dialog/savedbyme-message-dialog.component';
import { SavedmeMessageDialogComponent } from './savedme-message-dialog/savedme-message-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-saved',
  templateUrl: './saved.component.html',
  styleUrls: ['./saved.component.css'],
})
export class SavedComponent implements OnInit {
  @ViewChild('callDeleteFavCustomerDialog')
  callDeleteFavCustomerDialog: TemplateRef<any>;
  starCount: number = 5;
  starCountOverall: any;
  starColor: StarRatingColor = StarRatingColor.accent;
  customerId: string;
  savedbyme: any;
  savedme: any;
  selectedTab: string = 'Saved me';
  deleteSavedListingName: any;
  numberLength: number = 0;

  searchQuery: any =''; // Property to store the search query
  private searchSubject: Subject<string> = new Subject<string>(); // Subject to handle search input changes

  constructor(private savedService: SavedService,
    private userService: UserService,
    private coreService: CoreService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getSavedByMe();
    this.getSavedMe();

    // Subscribe to changes in the searchSubject to trigger search
    this.searchSubject.subscribe(query => {
      // Perform search logic based on the query
      if (this.selectedTab === 'Saved me') {
        this.getSavedMe();
      } else {
        this.getSavedByMe();
      }
    });

    // Optional: Fetch initial search query from route params
    this.activatedRoute.params.subscribe(params => {
      this.searchQuery = params['value'] || ''; // Initialize searchQuery from route if available
    });
  }

  clearFilter(){
    this.searchQuery = '';
    this.performSearch(); // Reload the list based on the current tab
  }

  // Method to handle search based on the current tab
  performSearch() {
    if (this.selectedTab === 'Saved me') {
      if (this.searchQuery.length === 0) {
        this.getSavedMe();
      } else {
        if (this.numberLength < this.searchQuery.length) {
          this.numberLength = this.searchQuery.length;
        }
        this.savedme = this.savedme.filter((item: any) =>
          item.listingName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    } else {

      if (this.searchQuery.length === 0) {
        this.getSavedByMe();
      } else {
        this.savedbyme = this.savedbyme.filter((item: any) =>
          item.listingName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    }
  }

  getSavedByMe() {
    this.savedService.getFavorite().subscribe((data) => {
      let sortedDataList = data?.items.map((element: any) => {
        const index = element.listingAddress.indexOf('{');
        if (index != -1) {
          element.listingAddress = this.getSortedData(element, index);
        }
        return element;
      });
      this.savedbyme = sortedDataList;
      console.log('this.savedbyme data ', this.savedbyme);
    });
  }


  getSortedData(element: any, index: any) {
    const newStr = element.listingAddress.substring(index);
    const jsonObjects = newStr.split('}').map((item: any) => {
      try {
        return JSON.parse(item + '}');
      } catch (e) {
        return null;
      }
    });

    const names = jsonObjects.map((obj: any) => obj ? obj.name : null);
    return ` ${names[0]}, ${names[1]}`;

  }

  onSearch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const query = inputElement.value;

    this.searchQuery = query;
    this.performSearch();
  }

  changeTab(status: string) {
    this.selectedTab = status;
  }

  getSavedMe() {
    let sortedDataList: any;
    this.savedService.getFavoriteSavedMe().subscribe((data) => {
      if (data?.list && data?.list.length > 0) {
        let sortedDataList = data?.list.map((element: any) => {
          const index = element.listingAddress.indexOf('{');
          if (index != -1) {
            element.listingAddress = this.getSortedData(element, index);
          }
          return element;
        });
        this.savedme = sortedDataList;
      }
    });
  }




  deleteSavedByMeListing(id: string, savedListingNameParam: string) {
    this.deleteSavedListingName = savedListingNameParam;
    let dialogRef = this.dialog.open(this.callDeleteFavCustomerDialog, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.savedService.deleteFavoriteListing(id).subscribe((data) => {
          this.getSavedByMe();
          this.showSnackbarTopPosition(
            'Saved Listing deleted successfully',
            'Done',
            '2000'
          );
          this.ngOnInit();
        })
      } else {
        if (result === 'cancel') {
          this.getSavedByMe();
        }
      }
    })
  }

  savedByMeMessage(id: any) {
    const dialogRef = this.dialog.open(SavedbymeMessageDialogComponent, {
      data: {
        savedByMeid: id,
      },
      height: '75vh',
      width: '60vw',
      disableClose: true
    });
  }

  deleteSavedMeListing(id: any, savedListingNameParam: any) {
    this.deleteSavedListingName = savedListingNameParam;
    let dialogRef = this.dialog.open(this.callDeleteFavCustomerDialog, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.savedService.deletesavedmeListing(id).subscribe((data) => {
          this.getSavedMe();
          this.showSnackbarTopPosition(
            'Saved Listing deleted successfully',
            'Done',
            '2000'
          );
          this.ngOnInit();
        })
      } else {
        if (result === 'cancel') {
          this.getSavedByMe();
        }
      }
    })
  }

  sendMessage(emailid: any, id: any) {
    const dialogRef = this.dialog.open(SavedmeMessageDialogComponent, {
      data: {
        listingName: emailid,
        listingid: id,
      },
      height: '75vh',
      width: '60vw',

    });
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'right', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }
}
