import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';
import { Settings } from '../_models/settings.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  isOpen$ = new BehaviorSubject(true);

  private apiUrl = 'https://jsonplaceholder.typicode.com/posts';

  constructor(private http: HttpClient) {
    const screenWidth = window.screen.width;
    if(screenWidth >= 992){
      this.isOpen$.next(true)
    }else{
      this.isOpen$.next(false)
    }
   }


    SaveSettings(settings: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, { settings });
  }

  getIsNav(){
    return this.isOpen$.asObservable();
   }
  toggleNav(){
    this.isOpen$.next(!this.isOpen$.value)
  }

  // Update user settings
  updateSettings(settings: Settings): Observable<Settings> {
    const url = `${environment.apiUrl}/customer/update/settings`;
    return this.http.put<Settings>(url, settings, { headers: this.headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error updating settings:', error);
        return throwError(error);
      })
    );
  }
}
