import { CoreService } from './../../_services/core.services';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';

@Component({
  selector: 'app-listing-business',
  templateUrl: './listing-business.component.html',
  styleUrl: './listing-business.component.css',
})
export class ListingBusinessComponent {
  listings: any;
  custid: any;
  userdetails: any;

  constructor(
    private listingInformationService: ListingInformationService,
    private coreService: CoreService,
    private router: Router
  ) {}

  ngOnInit() {
    this.custid = this.coreService.getCustomerId();
    this.userdetails = this.coreService.getUserDetails();
    this.getListings();
  }

  getListings() {
    this.listingInformationService.getAllListings().subscribe((data) => {
      this.listings = data?.list;
    });
  }

  getList(list: any) {
    this.router.navigate(['/listing-profile', list.id]);
  }
}
