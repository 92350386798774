<app-nav-bar></app-nav-bar>
<div class="container-fluid" style="margin-top: 80px">
  <h1 style="margin-top: 30px">Search results for {{ searchValue }}</h1>

  <div class="row">
    <div class="col-lg-3" *ngFor="let list of listings">
      <mat-card class="card-style">
        <img
          [routerLink]="['/s/pages/listing-profile', list.listingUserName]"
          src="{{ list.profileURL }}"
          alt="Image"
          class="rounded-top"
          style="width: 100%; height: 300px"
        />
        <!-- <span class="bookmarkicon">
          <span class="material-icons-outlined"> bookmark_border </span
          >&nbsp;Save</span -->
        <span
          class="visibleicon"
          [routerLink]="['/s/pages/listing-profile', list.listingUserName]"
          style="cursor: pointer"
        >
          <span class="material-icons-outlined"> visibility </span
          >&nbsp;Preview</span
        >
        <div class="p-3 description-text">
          <b
            [routerLink]="['/s/pages/listing-profile', list.listingUserName]"
            style="cursor: pointer"
            >{{ list.listingName }}</b
          >
          <p>{{ list.addressLine1 }}</p>
        </div>
        <hr />
        <div class="card-footer">
          <div>
            <span class="address-content"
              ><span class="material-icons-outlined"> location_on </span>
              {{ list.cityName }}, {{ list.country }}</span
            >
          </div>
          <div class="spacer"></div>
          <div class="date">
            <span style="color: rgba(4, 144, 4, 0.929)">Open Now</span>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
