import { Component, EventEmitter, Inject, Output} from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from 'src/app/_services/core.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { ReviewService } from 'src/app/_services/review.services';
import { UserService } from 'src/app/_services/user.service';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
@Component({
  selector: 'app-review-edit',
  templateUrl: './review-edit.component.html',
  styleUrls: ['./review-edit.component.css'],
})
export class ReviewEditComponent {
  starCountQuality: any = 5;
  ratingQuality: any = 0;
  starCountPrice: any = 5;
  ratingPrice: any = 0;
  starCountOverall: any = 5;
  ratingOverall: any = 0;
  starCountServices: any = 5;
  ratingServices: any = 0;
  starCountPunctuality: any = 5;
  ratingPunctuality: any = 0;
  starColor: StarRatingColor = StarRatingColor.accent;
  starColorP: StarRatingColor = StarRatingColor.primary;
  starColorW: StarRatingColor = StarRatingColor.warn;


  currentRate = 0;
  reviews: any;
  listings: any;
  businessid: any;
  listingid: any;
  listingTitle: any;
  addReviewForm: FormGroup;
  listingName: string;
  customerUserName: string;
  userInfo: any;
  filteredReview: any;
  isEdit: boolean = false;
  singleListingData: any;
  listingAddress: any;

  @Output() reviewUpdated = new EventEmitter<void>();

  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any,
    public _formBuilder: FormBuilder,
    public reviewService: ReviewService,
    private coreService: CoreService,
    private listingInformationService: ListingInformationService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.mainForm();
    this.getListingDetails();
    this.readReview();
    this.getListingDetails();
    this.userInfo = this.coreService.getUserDetails();
    this.businessid = this.coreService.getCustomerId();
    this.customerUserName = this.userInfo?.username;
  }

  // files: any;
  // fileName: string;
  // getFile(e: any) {
  //   this.files = e;
  //   this.fileName = e.name;
  // }

  files: any[] = [];
//fileName: any[] = [];
getFile(e: any) {
  this.files.push(e);
 // this.fileName.push(e.name);
}

  mainForm() {
    this.addReviewForm = this._formBuilder.group({

      reviewTitle: [''],
      reviewDescription: [''],
      listingName:[''],


    });
  }

  readReview() {
    this.reviewService.getReview().subscribe((data) => {
      this.reviews = data?.list;
      this.ratingPunctuality = this.reviews.punctuality

      this.filteredReview = this.reviews.filter((r: any) => r.id === this.data.reviewId);
      if(this.data.reviewId){
        this.isEdit = true
        this.ratingPunctuality = this.filteredReview[0].punctuality
        this.ratingServices = this.filteredReview[0].service
        this.ratingQuality = this.filteredReview[0].quality
        this.ratingPrice = this.filteredReview[0].price
        this.ratingOverall = this.filteredReview[0].overall
      this.addReviewForm.patchValue({
        reviewTitle: this.filteredReview[0].reviewTitle,
        reviewDescription: this.filteredReview[0].reviewDescription,
    });
  }
  })
  }


  onRatingChanged(rating: any, type: string) {
    switch (type) {
      case 'punctuality':
        this.ratingPunctuality = rating;
        break;
      case 'price':
        this.ratingPrice = rating;
        break;
      case 'quality':
        this.ratingQuality = rating;
        break;
      case 'services':
        this.ratingServices = rating;
        break;
      case 'overall':
        this.ratingOverall = rating;
        break;
    }
  }


  onReviewFormSubmit() {
    const category = 'review'
    const status = 'active'
    const listingid = this.singleListingData.id
    const listingName = this.singleListingData.listingName
    if(this.isEdit = true){
      this.reviewService.updateReview(
        this.data.reviewId,
        // this.businessid,
        // this.customerUserName,
        listingid,
        listingName,
        this.addReviewForm.value.reviewTitle,
        this.ratingPunctuality,
        this.ratingServices,
        this.ratingQuality,
        this.ratingPrice,
        this.ratingOverall,
        this.addReviewForm.value.reviewDescription,
        status,
        category,
        this.files).subscribe((data: any) => {
          this.reviewUpdated.emit();
          this.showSnackbarTopPosition('Review updated successfully', 'Done', '2000');
        });


    }else{
    this.reviewService.createReview(
      // this.businessid,
      // this.customerUserName,
      this.listingid,
      // this.listings.listingName,
      // this.listingAddress,
      this.addReviewForm.value.reviewTitle,
      this.ratingPunctuality,
      this.ratingServices,
      this.ratingQuality,
      this.ratingPrice,
      this.ratingOverall,
      this.addReviewForm.value.reviewDescription,
      status,
      category,
      this.files).subscribe((data: any) => {
        this.reviewUpdated.emit();
        this.showSnackbarTopPosition('Review submitted successfully', 'Done', '2000');

      });
  }
}

  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "right" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  getListingDetails() {
    this.listingInformationService
      .getAll(this.data.review.reviewToListingId)
      .subscribe((data) => {
        // this.listings = data?.list;
        let resData = data?.data;
        if(resData.cityName && this.isValidJSON(resData.cityName)){
          resData.cityName = JSON.parse(resData.cityName).name
        }
        if(resData.state && this.isValidJSON(resData.state)){
          resData.state = JSON.parse(resData.state).name
        }
        if(resData.country && this.isValidJSON(resData.country)){
          resData.country = JSON.parse(resData.country).name;
        }
        this.singleListingData = resData;
        // this.singleListingData = this.listings.find((r: any) => r.id == this.data.review.reviewToListingId)
        this.addReviewForm.patchValue({
          ...this.addReviewForm.getRawValue(),

          listingName: this.singleListingData?.id,
         })

this.onListChange(this.singleListingData?.id);
         this.addReviewForm.get('listingName')?.disable();
         this.addReviewForm.updateValueAndValidity();

      });

        }
        isValidJSON(str:any) {
          try {
            JSON.parse(str);
            return true;
          } catch (e) {
            return false;
          }
        }

        onListChange(value: any) {
          this.listingid = value;
        }


}


