import { Component } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { SignupService } from 'src/app/_services/signup.services';
import { matchpassword } from './matchpassword';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent {
  signUpForm: FormGroup;
  isButtonDisabled: boolean = false;
  userNameExists: Observable<boolean>;
  userData: any;
  hideCurrentPassword = true;
  hideConfirmPassword = true;
  customerBaseUrl: string;

  constructor(
    private router: Router,
    private signupService: SignupService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.customerBaseUrl = environment.customerBaseUrl;

    this.mainForm();
    this.subscribeToUserNameChanges();
  }

  mainForm() {
    this.signUpForm = this.formBuilder.group(
      {
        firstName: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z ]*$'),
          ],
        ],
        lastName: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z ]*$'),
          ],
        ],
        userName: [
          '',
          {
            validators: [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9_.-]+$'),
            ],
            asyncValidators: [this.userNameValidator()],
            updateOn: 'blur',
          },
        ],
        emailAddress: [
          '',
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z]+\\.[a-z]{2,4}$'),
          ],
        ],
        password: [
          '',
          [
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
            ),
          ],
        ],
        phoneNumber: [
          '',
          [Validators.required, Validators.pattern(/^\d{10}$/)],
        ],
        confirmPassword: ['', Validators.required],

        checkBox: ['', Validators.required],

      },
      {
        validators: matchpassword,
      }
    );
  }

  userNameValidator(value?: any): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const userName = control.value;
      return this.signupService.checkUserNameExists(userName).pipe(
        map((result: any) => {
          return result?.result ? { userNameExists: true } : null;
        }),
        catchError(() => {
          return of(null);
        })
      );
    };
  }

  subscribeToUserNameChanges() {
    const userNameControl = this.signUpForm.get('userName');
    if (userNameControl) {
      userNameControl.valueChanges.subscribe((value) => {
        this.checkUserNameExists(value);
        this.userNameValidator(value);
      });
    }
  }

  checkUserNameExists(userName: string) {
    this.signupService.checkUserNameExists(userName).subscribe((response) => {
      this.userData = response;
    });
  }

  public validation_messages = {
    firstName: [{ type: 'required', message: 'First Name is required' }],
    lastName: [{ type: 'required', message: 'Last Name is required' }],
    userName: [{ type: 'required', message: 'User Name is required' }],
    emailAddress: [{ type: 'required', message: 'Email address is required' }],
    phoneNumber: [{ type: 'required', message: ' Phone number is required' }],
    password: [{ type: 'required', message: 'Password is required' }],
    confirmPassword: [
      { type: 'required', message: 'Confirm Password is required' },
    ],
  };

  onsignUpFormSubmit() {
    this.isButtonDisabled = true;
    let obj = {
      firstName: this.signUpForm.value.firstName,
      lastName: this.signUpForm.value.lastName,
      userName: this.signUpForm.value.userName,
      emailAddress: this.signUpForm.value.emailAddress,
      password: this.signUpForm.value.password,
      phoneNumber: this.signUpForm.value.phoneNumber,
      confirmPassword: this.signUpForm.value.confirmPassword,
    };

    this.signupService.signup(obj).subscribe((data) => {
      // alert('Account created')
      this.router.navigateByUrl('/s/pages/dashboard');
    });
  }

  logIn() {
    this.router.navigateByUrl('/s/pages/dashboard');
  }

  signUp() {
    this.router.navigateByUrl('/signup');
  }
  updatePhoneNumberValidity() {
    const phoneNumberControl = this.signUpForm.get('phoneNumber');
    if (phoneNumberControl) {
      phoneNumberControl.updateValueAndValidity();
    }
  }

  isValidKey(key: string, controlName: string): boolean {
    let validCharacters;
    switch (controlName) {
      case 'phoneNumber':
        validCharacters = /^[0-9]+$/;
        break;
      default:
        return true;
    }
    return validCharacters.test(key);
  }
  formatPhoneNumber(event: Event) {
    const phoneNumberControl = this.signUpForm.get('phoneNumber');
    if (phoneNumberControl) {
      let input = (event.target as HTMLInputElement).value.replace(/\D/g, '');

      // Limit input to 10 digits
      if (input.length > 10) {
        input = input.substring(0, 10);
      }

      phoneNumberControl.setValue(input, { emitEvent: false });
    }
  }
  toggleVisibility(field: string): void {
    if (field === 'password') {
      this.hideCurrentPassword = !this.hideCurrentPassword;
    } else if (field === 'confirmPassword') {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    }
  }
  handleInput(event: any, formControlName: string) {
    const input = event.target;
    const pattern = /^[a-zA-Z ]*$/; // Pattern to allow only alphabets and spaces
    const value = input.value;

    // Filter out any characters that do not match the pattern
    const filteredValue = value.replace(/[^a-zA-Z ]/g, '');

    // If the filtered value is different from the current value, update the form control
    if (value !== filteredValue) {
      this.signUpForm.get(formControlName)?.setValue(filteredValue);
    }
  }
}
