import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ApiService } from './_services/reviews.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigService } from './auth/auth-config.service';
import { AuthGuard } from './auth/auth.guard';
import { AngularMaterialModule } from './material-imports';
// import { LocalStorageService } from './_services/local-storage.service';
import { CommonModule } from '@angular/common';
import { RouterComponent } from './components/router/router.component';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { SharedModule } from './shared/shared.module';

function initializeKeycloak(
  keycloak: KeycloakService,
  authConfig: AuthConfigService
) {
  return () =>
    keycloak.init({
      config: {
        url: authConfig.auth,
        realm: authConfig.realm,
        clientId: authConfig.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-renew.html',
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: [],
    });
}

@NgModule({
  declarations: [AppComponent, RouterComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    KeycloakAngularModule,
    NgxChartsModule,
    FormsModule,
    CommonModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    ApiService,
    AuthGuard,
    AuthConfigService,
    // LocalStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthConfigService],
    },
    {
      provide: Window,
      useValue: window,
    },

    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

