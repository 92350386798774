import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivitiesService } from 'src/app/_services/activity.services';

@Component({
  selector: 'app-date-filter-dialog',
  templateUrl: './recent-feeds-date-filter-dialog.component.html',
  styleUrl: './recent-feeds-date-filter-dialog.component.css'
})
export class RecentFeedsDateFilterDialogComponent {
  @Output() activities = new EventEmitter();

  toDate: Date;
  fromDate: Date;
  activityDateForm: FormGroup;


  constructor(private activitiesService: ActivitiesService,
    public dialogRef: MatDialogRef<RecentFeedsDateFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ){

  }

  ngOnInit(){
    this.mainForm();
  }

  mainForm() {
    this.activityDateForm = this.formBuilder.group({
      fromDate: [new Date(),''],
      toDate: [new Date(),''],
    });
  }

  get myForm() {
    return this.activityDateForm.controls;
  }

  closeDialog(){
    const fromDate = this.activityDateForm.value.fromDate;
    const toDate = this.activityDateForm.value.toDate;
    this.dialogRef.close({fromDate: fromDate, toDate: toDate})
  }

}
