<div class="conatiner" style="padding: 20px; background-color: #fff;">
  <mat-dialog-content>
  <div class="row">
    <div class="col-md-6">
      <h1><b>Contact {{this.sendMessageData?.listingName}}</b></h1>
    </div>
    <div class="col-md-6">
      <button mat-mini-fab mat-dialog-close style="float: right; background-color: #fff; color: black;">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>

<form [formGroup]="sendMessageForm" (ngSubmit)="onMessageFormSubmit()" class="my-4" >
  <div class="row">
    <p style="margin-left: 10px; margin-top: -10px; margin-bottom: 0px;">Listing Name</p>
<mat-form-field appearance="outline" class="example-full-width custom-field">
  <input matInput formControlName="listingName" readonly>
</mat-form-field>
<p style="margin-left: 10px; margin-top: -10px; margin-bottom: 0px;">Subject</p>
<mat-form-field appearance="outline" class="example-full-width custom-field">
  <input matInput formControlName="subjectContent" placeholder="Enter subject">
</mat-form-field>
<p style="margin-left: 10px; margin-top: -10px; margin-bottom: 0px;">Message</p>
<mat-form-field appearance="outline" class="custom-field">
  <textarea matInput rows="4" formControlName="messageContent" placeholder="Enter Message"></textarea>
</mat-form-field>
</div>

<button mat-raised-button *ngIf="!sendMessageForm.valid"
style="float: right; border-radius: 10px; width:150px; height: 50px;" type="submit" [disabled]="!sendMessageForm.valid" mat-dialog-close>
Submit
</button>
<button mat-raised-button *ngIf="sendMessageForm.valid"  style="float: right; border-radius: 10px; width:150px; height: 50px;
 background-color: #525EB1; color:#fff" type="submit" mat-dialog-close>
Submit
</button>

<button mat-raised-button type="button" mat-dialog-close style="float: right; border-radius: 10px; width:150px; height: 50px;" class="mx-2">
Cancel
</button>


</form>

</mat-dialog-content>
<div style="margin-top: 10px;"></div>
</div>
