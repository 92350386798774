import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}
  
  private imageUrlSubject = new BehaviorSubject<string>('default-image-url');
  imageUrl$ = this.imageUrlSubject.asObservable();
  user$ = new BehaviorSubject(null);
  updateUser(user: any) {
    this.user$.next(user);
  }

  getUser() {
    return this.user$.asObservable();
  }
}
