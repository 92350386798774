import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private httpClient: HttpClient) {}

  //getAll Notifications
  getNotifications() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/notification/list`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }


  //delete single Noti
  deleteNotification(notificationId: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/notifications/one/${notificationId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //delete all noti
  deleteAllNotification(notificationData: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/notifications/all`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateAll(notificationData: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/notifications/update/all`, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  singleNotiRead(notificationId: any): Observable<any> {
      const headers = { 'content-type': 'application/json' };
      return this.httpClient
        .put<any>(`${environment.apiUrl}/notifications/update/one/${notificationId}`, { headers })
        .pipe(
          catchError((err) => {
            console.error(err);
            throw err;
          })
        );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
