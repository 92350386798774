<div class="container" style="padding-bottom: 20px;">
  <div class="row" style="padding: 20px;">
    <div class="col-sm-6">
  <h1>Edit Review</h1>
    </div>
    <div class="col-sm-6">
      <button mat-mini-fab  mat-dialog-close style="float: right; background-color: #fff; color: black;">
        <mat-icon>close</mat-icon>
    </button>
        </div>
    </div>
    <form [formGroup]="addReviewForm" (ngSubmit)="onReviewFormSubmit()">
      <div class="row" style="padding-left:20px">
        <p>Listing Title</p>
        <mat-form-field appearance="outline" class="example-full-width custom-field" *ngIf="singleListingData">
          <mat-select formControlName="listingName" (selectionChange)="onListChange($event?.value)">
            <mat-option [value]="singleListingData.id"><b>{{
                singleListingData?.listingName
                }}</b>-<p>
                {{singleListingData?.addressLine1}}&nbsp;{{singleListingData?.cityName}}&nbsp;{{singleListingData?.state}}
              </p></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row" style="padding-left:20px">
        <p>Review Title</p>
        <mat-form-field appearance="outline" class="example-full-width custom-field">
          <input matInput formControlName="reviewTitle">
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col" style="display:flex">
          <p style="width:100px; margin-left:100px; margin-top:8px">Punctuality</p>
          <app-star-rating style="margin:-7px" [rating]="ratingPunctuality" [starCount]="starCountPunctuality"
            [color]="starColor" (ratingUpdated)="onRatingChanged($event,'punctuality')">
          </app-star-rating>
        </div>
        <div class="col" style="display:flex">
          <p style="width:100px;; margin-top:8px">Service</p>
          <app-star-rating style="margin:-7px" [rating]="ratingServices" [starCount]="starCountServices"
            [color]="starColor" (ratingUpdated)="onRatingChanged($event,'services')">
          </app-star-rating>
        </div>
      </div>
      <div class="row">
        <div class="col" style="display:flex">
          <p style="width:100px; margin-left:100px; margin-top:8px">Quality</p>
          <app-star-rating style="margin:-7px" [rating]="ratingQuality" [starCount]="starCountQuality" [color]="starColor"
            (ratingUpdated)="onRatingChanged($event,'quality')">
          </app-star-rating>
        </div>
        <div class="col" style="display:flex">
          <p style="width:100px;">Price</p>
          <app-star-rating style="margin:-7px" [rating]="ratingPrice" [starCount]="starCountPrice" [color]="starColor"
            (ratingUpdated)="onRatingChanged($event,'price')">
          </app-star-rating>
        </div>
      </div>
      <div class="row">
        <div class="col" style="display:flex">
          <p style="width:100px; margin-left:100px; margin-top:8px">Overall</p>
          <app-star-rating style="margin:-7px" [rating]="ratingOverall" [starCount]="starCountOverall" [color]="starColor"
            (ratingUpdated)="onRatingChanged($event,'overall')">
          </app-star-rating>
        </div>
      </div>
      <div class="row" style="padding-left:20px">
        <p>Review</p>
        <mat-form-field class="example-full-width custom-field" appearance="outline">
          <textarea matInput formControlName="reviewDescription"
            placeholder="Detail description about your experience"></textarea>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="isEdit" style="padding-left:20px">
        <div class="col-sm-4" *ngFor="let reviewImageUrls of filteredReview[0]?.reviewImageUrls">
          <img src="{{reviewImageUrls}}" style="width: 300px; height: 300px;">
        </div>
        <div class="col-sm-12">
          <app-multiple-drag-and-drop-img [businessid]="businessid" [listingid]="listingid" (sendFile)="getFile($event)"
            multipleAppDragAndDropImg>
          </app-multiple-drag-and-drop-img>
        </div>
      </div>
      <app-multiple-drag-and-drop-img *ngIf="!isEdit" [businessid]="businessid" [listingid]="listingid"
        (sendFile)="getFile($event)" multipleAppDragAndDropImg>
      </app-multiple-drag-and-drop-img>
      <br>
      <button mat-raised-button type="submit"
        style="float: right; border-radius: 10px; width:150px; height: 50px; background-color: #525EB1; color:#fff"
        mat-dialog-close>
        Submit Review
      </button>

      <button mat-raised-button mat-dialog-close type="button"
        style="float: right; border-radius: 10px; width:150px; height: 50px;" class="mx-2">
        Cancel
      </button>
    </form>
  </div>
