import { Reviews } from './../../_models/review.model';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class StarRatingComponent implements OnInit {
  @Input('reviews') public reviews: any = 0;
  @Input('starCount') public starCount: number = 5;
  @Input('isReviewShow') public isReviewShow = false;
  @Input('rating') public rating: number = 0;
  @Input('color') public color: string = 'accent';
  @Output() public ratingUpdated = new EventEmitter();

  private snackBarDuration: number = 2000;
  public ratingArr: any[] = [];

  constructor() {}

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(review: number) {
    this.ratingUpdated.emit(review);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}

export enum StarRatingColor {
  primary = 'primary',
  accent = 'accent',
  warn = 'warn',
}
