import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from 'src/app/_services/core.services';
import { SavedService } from 'src/app/_services/saved.service';

@Component({
  selector: 'app-savedbyme-message-dialog',
  templateUrl: './savedbyme-message-dialog.component.html',
  styleUrl: './savedbyme-message-dialog.component.css'
})
export class SavedbymeMessageDialogComponent {
  sendMessageForm: FormGroup;
  customerSavedMe: any;
  sendMessageData: any;
  sendListingEmail: any;
  userInfo: any;

  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any,
  public _formBuilder: FormBuilder,
  private savedService: SavedService,
  private snackBar: MatSnackBar,
  private coreService: CoreService
  ){
  }

  ngOnInit(){
    this.mainForm();
    this.userInfo = this.coreService.getUserDetails();
    this.settingSavedByMeName();
  }


  mainForm() {
    this.sendMessageForm = this._formBuilder.group({
      listingName: [''],
      subjectContent: ['',Validators.required],
      messageContent: ['',Validators.required],

    });
  }

  settingSavedByMeName(){
      this.savedService.getFavoriteListings().subscribe((data) => {
        this.customerSavedMe = data?.items;
         this.sendMessageData = this.customerSavedMe.find((r: any) => r.id === this.data.savedByMeid),
         this.sendMessageForm.patchValue({
          listingName: this.sendMessageData.listingName,
         })
      })
  }

  onMessageFormSubmit(){
    let obj =  {
      senderEmail: this.userInfo.email,
      receiverName: this.sendMessageData?.listingName,
      receiverId: this.sendMessageData?.savedListingId,
      senderName: this.userInfo.firstName,
      subjectContent: this.sendMessageForm.value.subjectContent,
      messageContent: this.sendMessageForm.value.messageContent
    }
    this.savedService.sendMessageTo(obj).subscribe((data)=>{
      this.showSnackbarTopPosition('Message Sent successfully', 'Done', '1000');
    })
  }


  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "right" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }
}
