import { HttpEventType } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadFilesService } from 'src/app/_services/upload-files.service';
import { ListDataModal } from 'src/app/_models/list-modal';
import { CoreService } from 'src/app/_services/core.services';
import heic2any from 'heic2any';

@Component({
  selector: 'app-multiple-drag-and-drop-img',
  templateUrl: './multiple-drag-and-drop-img.component.html',
  styleUrls: ['./multiple-drag-and-drop-img.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultipleDragAndDropImgComponent,
      multi: true,
    },
  ],
})
export class MultipleDragAndDropImgComponent implements ControlValueAccessor, OnInit {
  @Input() businessid: string;
  @Input() listingid: string;
  @Input() category: string;
  @Input() isReadOnly: boolean;
  @Output() sendFile = new EventEmitter();
  @Output() delFile = new EventEmitter();

  @ViewChild('callDeleteConfirmationDialog')
  callDeleteConfirmationDialog: TemplateRef<any>;
  @ViewChild('cancelConfirmationDialog')
  cancelConfirmationDialog: TemplateRef<any>;
  @ViewChild('MultifileDropRef')
  MultifileDropRef: ElementRef<any>;

  data: ListDataModal = {} as ListDataModal;
  public files: any[] = [];
  listOfFiles: string[] = [];
  fileToBeDeleted: string;
  displayedColumns: string[] = ['fileName', 'action'];
  galleryimage: any[] = [];
  bannerimagurl: any;
  promotionimage: string = '';
  categoryType: string;
  onChange: (files: any) => void = () => {};

  constructor(
    private filesApiService: UploadFilesService,
    public dialog: MatDialog,
    public coreApiService: CoreService,
    private snackBar: MatSnackBar
  ) {}

  // Angular form control value accessor
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit() {
    this.getFilesUploaded();
  }

  displayDragAndDropArea() {
    if (this.isReadOnly) {
      return false;
    } else {
      return true;
    }
  }

  getFilesUploaded() {
    if (this.listingid) {
      this.filesApiService
        .getFiles(this.listingid)
        .subscribe((response) => {
          console.log(response);
          if(response && response?.data && response?.data?.galleryImageUrl){
            this.galleryimage = response?.data?.galleryImageUrl;
          }
          if(response && response?.data && response?.data?.promotionImageUrl){
            this.promotionimage = response?.data?.promotionImageUrl
          }
          this.handleFormValue();
        });
    }
  }

  uploadFiles(file: any, category:any) {
    this.sendFile.emit(file);
    this.getFilesUploaded();

  }

  onFileDropped(file: any,category?:any) {
    // this.prepareFilesList(file,category);
    this.handleFile(category, file)

  }

  fileBrowseHandler(e: any, category?:any) {
    const file= e.target.files;
    if (file.length === 0)return;
    this.handleFile(category, file)
    // for(let i = 0; i< file.length; i++){
    //   const reader = new FileReader();
    // reader.readAsDataURL(file[i]);
    // reader.onload = (_event) => {
    //   this.galleryimage.push(reader.result);
    // }
    // }
    // this.prepareFilesList(file, category);
  }


  async convertHeicToJpeg(file: File): Promise<Blob> {
    try {
      const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
      return convertedBlob as Blob;
    } catch (error) {
      console.error('Error converting HEIC file:', error);
      throw error;
    }
  }

  private async handleFile(category:any,file:any): Promise<void> {
    for(let i = 0; i< file.length; i++){
      if (file[i]?.name?.split(".")[1]=== 'HEIC' || file[i]?.name?.split(".")[1]=== 'heic'){
        try {
          const jpegBlob = await this.convertHeicToJpeg(file[i]);
          this.galleryimage.push(URL.createObjectURL(jpegBlob));
          const filesJpeg = new File([jpegBlob], file[i]?.name?.split(".")[0]);
          this.uploadFiles(filesJpeg, category);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      }
      else if (file[i].type.match('image.*')) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.galleryimage.push( event.target?.result);
        };
        reader.readAsDataURL(file[i]);
        this.uploadFiles(file[i], category);

        // this.prepareFilesList(file, category);
      } else {
        console.error('Unsupported file type');
      }
    }
  }


  prepareFilesList(files: Array<any>, category?:any) {
    let fileUploadSizeExceeds = false;
    let fileUploadSizeFailedList = '';
    for (const item of files) {
      item.completed = false;
      if (Number(item.size) < 8e11) {
        this.files.push(item);
        this.uploadFiles(item,category);
      } else {
        if (fileUploadSizeExceeds) {
          fileUploadSizeFailedList += ',  ';
        }
        fileUploadSizeFailedList += item.name;
        fileUploadSizeExceeds = true;
      }
    }
    if (fileUploadSizeExceeds) {
      let snackBarMessage =
        fileUploadSizeFailedList +
        ' is greater than 10 MB. Please upload file less than 10 MB ';
      this.snackBar.open(snackBarMessage, 'X', {
        duration: 7 * 1000,
        panelClass: ['error-snackbar'],
      });
    }
    this.handleFormValue();
  }

  formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 100240;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  handleFormValue() {
    if (this.files.length + this.data.total > 0) {
      this.onChange(this.files.length + this.data.total);
    } else {
      this.onChange(null);
    }
  }

  delImg(i: any){
    this.delFile.emit(i);
    this.galleryimage.splice(i, 1);

  }

}
