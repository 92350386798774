export const environment = {
  production: false,
  wsUrl: 'https://api.dev.qup.global/web-socket',
  paymentUrl: 'https://api.dev.qup.global/payment-services',
  apiUrl: 'https://api.dev.qup.global/api/qup-services',
  customerBaseUrl: 'https://dev.qup.global',
  businessBaseUrl: 'https://business.dev.qup.global',
  httpInterceptor: {
    allowedList: [`/api/qup-services/*`, `https://*.qup.global/api/*`],
  },
};
