import { Component, TemplateRef, ViewChild } from '@angular/core';
import { SavedService } from 'src/app/_services/saved.service';
import { Router } from '@angular/router';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { UserService } from 'src/app/_services/user.service';
import { GalleryService } from 'src/app/_services/gallery.services';
import { CoreService } from 'src/app/_services/core.services';
import { MatDialog } from '@angular/material/dialog';
import { IGallery } from 'src/app/_models/gallery.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaggedMemberDialogComponent } from './tagged-member-dialog/tagged-member-dialog.component';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent {
  selectedTab: string = 'My Posts';
  custid: string;
  listingid: any;
  listings: any;
  gallery: any[] = [];
  galleryColumns: any;
  RequestedgalleryColumns: any;
  TaggedgalleryColumns: any;
  dataFromGallery: any;
  postDataTotal: 0;
  givenTaggedPostDataTotal = 0;
  tagRequestDataTotal = 0;
  taggedDataTotal = 0;
  pageSize = 20;
  pageIndex = 0;


  Array = Array;
  @ViewChild('callDeleteGalleryDialog')
  callDeleteGalleryDialog: TemplateRef<any>;

  @ViewChild('callImageGalleryDialog')
  callImageGalleryDialog: TemplateRef<any>;

  @ViewChild('callUntagDialog')
  callUntagDialog: TemplateRef<any>;

  constructor(
    private router: Router,
    private galleryService: GalleryService,
    private coreService: CoreService,
    private dialog: MatDialog,
    private listingInformationService: ListingInformationService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.custid = this.coreService.getCustomerId();
    this.readGallery();
    this.getListings();
  }

  changeTab(status: string) {
    this.selectedTab = status;
    if (this.selectedTab === 'My Posts') {
      this.readGallery();
    }
    if (this.selectedTab === 'Tag Requests') {
      this.taggedRequest();
    }
    if (this.selectedTab === 'Tagged Posts') {
      this.taggedPost();
    }
  }

  pageChange(pageSize: any, pageIndex: any) {
    this.pageSize = pageSize;
    this.pageIndex = pageIndex + 1;
    this.readGallery();
    this.taggedPost();
    this.taggedRequest();
  }

  async readGallery() {
    this.gallery = [];
    this.galleryColumns = [];
    this.galleryService
      .getBusinessGallery()
      .subscribe((data: any) => {
        if (data?.list && data?.list.length > 0) {
          this.gallery = data.list.filter((list: any) => list.status !== 'Deleted');
          this.postDataTotal = data?.total;
          const len = this.gallery?.length || 0;
          this.galleryColumns = this.gallery?.reduce(
            (acc, item, index) => {
              const accIndex = index % 4;
              acc[accIndex].push(item);
              return acc;
            },
            [[], [], [], []]
          );
        }
      });
  }
  // get galleryColumns(){
  //   const len = this.gallery?.length || 0;
  //   return this.gallery.reduce((acc,item, index)=>{
  //     const accIndex = index%4;
  //     acc[accIndex].push(item);
  //     return acc;
  //   }, [[], [], [], []])
  // }

  getListings() {
    this.listingInformationService.getallListings().subscribe((data: any) => {
      this.listings = data?.list;
    });
  }

  findListingGallery(e: any) {
    this.listingid = e.value;
    this.sortByListing();
  }

  sortByListing() {
    this.gallery = [];
    this.galleryService
      .getListingGallery(this.listingid)
      .subscribe((data: any) => {
        this.gallery = data?.items;
        this.galleryColumns = this.gallery?.reduce(
          (acc, item, index) => {
            const accIndex = index % 4;
            acc[accIndex].push(item);
            return acc;
          },
          [[], [], [], []]
        );
      });
  }

  addToFeatured(id: string) {
    let featured = true;
    this.galleryService.addFeatureImage(id, featured).subscribe((data) => {
      this.showSnackbarTopPosition('Image is featured now', 'Done', '2000');
      this.readGallery();
    });
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'right', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  editImage(gall: any) {
    this.router.navigate([
      '/s/pages/add-new-image',
      {
        imageid: gall.id,
      },
    ]);
  }
  deleteImg(id: string) {
    let dialogRef = this.dialog.open(this.callDeleteGalleryDialog, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.galleryService.deleteGallery(id).subscribe((data) => {
          this.gallery?.splice(parseInt(id, 10), 1);
          this.readGallery();
        });
      } else {
        if (result === 'cancel') {
          this.readGallery();
        }
      }
    });
  }
  addNewImage() {
    this.router.navigateByUrl('/s/pages/add-new-image');
  }

  taggedPost() {
    this.gallery = [];
    this.TaggedgalleryColumns = [];
    this.galleryService.getTaggedPost().subscribe((data: any) => {
      this.gallery = data?.list;
      this.taggedDataTotal = data?.total;
      // const len = this.TaggedgalleryColumns?.length || 0;
      this.TaggedgalleryColumns = this.gallery?.reduce(
        (acc, item, index) => {
          const accIndex = index % 4;
          acc[accIndex].push(item);
          return acc;
        },
        [[], [], [], []]
      );
    });
  }

  taggedRequest() {
    this.gallery = [];
    this.RequestedgalleryColumns = [];
    this.galleryService.getTaggedRequest().subscribe((data: any) => {
      this.gallery = data?.list;
      this.tagRequestDataTotal = data?.total;
      // const len = this.RequestedgalleryColumns?.length || 0;
      this.RequestedgalleryColumns = this.gallery?.reduce(
        (acc, item, index) => {
          const accIndex = index % 4;
          acc[accIndex].push(item);
          return acc;
        },
        [[], [], [], []]
      );
    });
  }

  updateTagRequest(imageId: any, taggedId: any, status: any) {
    this.galleryService
      .updateRequest(imageId, taggedId, status)
      .subscribe((data: any) => {
        this.showSnackbarTopPosition(
          'Tag request is ' + status,
          'Done',
          '2000'
        );
        this.taggedRequest();
      });
  }

  // viewTaggedList(id: any){
  //   const dialogRef = this.dialog.open(DisplayTagRequestDialogComponent, {
  //     data: { imageid: id  },
  //     height: '60vh',
  //     width: '50vw',
  //   });
  // }

  GalleryPage(image: any) {
    if (image) {
      this.dataFromGallery = image;
    }
    const dialogRef = this.dialog.open(this.callImageGalleryDialog, {
      height: '486px',
      width: '873px',
      disableClose: true,
    });
  }

  taggedMembers(id: any) {
    const dialogRef = this.dialog.open(TaggedMemberDialogComponent, {
      data: { imageid: id },
      height: '486px',
      width: '873px',
      disableClose: true,
    });
    dialogRef.componentInstance.taggedImageUpdated.subscribe(() => {
      this.ngOnInit();
      this.changeTab('My Posts');
    });
  }

  // untagYourself(gall: any){
  //   const dialogRef = this.dialog.open(this.callUntagDialog, {
  //     data: { gall  },
  //     height: '60vh',
  //     width: '50vw',
  //     disableClose: true
  //   });
  // }

  untagYourself(gall: any) {
    let dialogRef = this.dialog.open(this.callUntagDialog, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        let tagRequestStatus = 'denied';
        this.galleryService
          .updateRequest(
            gall.imageId,
            gall.taggedListDetails[0]?.taggedId,
            tagRequestStatus
          )
          .subscribe((data: any) => {
            this.taggedPost();
          });
      } else {
        if (result === 'no') {
          this.taggedPost();
        }
      }
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
