<div class="container">
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-10">
                <h1>Filter</h1>
            </div>
            <div class="col-md-2">
                <button mat-mini-fab mat-dialog-close style="float: right; background-color: #fff; color: #333333;">
                    <mat-icon>close</mat-icon>
                  </button>
            </div>
        </div>

        <div class="modal-body">
            <form [formGroup]="activityDateForm">
                <div class="row">
                    <div class=" "> <mat-label>From date</mat-label> <br>
                        <mat-form-field class="example-full-width custom-field" appearance="outline">

                            <input matInput [matDatepicker]="picker1" formControlName="fromDate" />
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>

                        </mat-form-field>
                    </div>
                    <div class=" "> <mat-label>To date</mat-label> <br>
                        <mat-form-field class="example-full-width custom-field" appearance="outline">

                            <input matInput [matDatepicker]="picker2" formControlName="toDate" />
                            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <button mat-raised-button class="button" (click)="closeDialog()">
                    Filter
                </button>

            </form>
        </div>
    </mat-dialog-content>
</div>
