import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest, } from '@angular/common/http';
import { IActivities } from '../_models/activity.model';
import { ResponseModal } from '../_models/response-modal';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  constructor(private httpClient: HttpClient) {}

  createActivity(
    businessId: string,
    listingId: string,
    fileName: string,
    category: string,
    file: File,
    title: string,
    description: string,
    activityDate: any,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('businessId', businessId);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('activityDate', activityDate);
    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/activity`,
      formData
    );

    return this.httpClient.request(req);
  }

  updateActivity(
    activityId: string,
    businessId: string,
    listingId: string,
    fileName: string,
    category: string,
    file: File,
    title: string,
    description: string,
    activityDate: any,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('activityId', activityId);
    formData.append('businessId', businessId);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('activityDate', activityDate);
    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/activity`,
      formData
    );

    return this.httpClient.request(req);
  }

  getActivityByListingId(listingId: string){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/publicUri/activity/listing?listingId=${listingId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getActivitiesByListingId(listingId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/publicUri/activity/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }


  getActivitiesdata(listingId: string) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/publicUri/activity/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getActivity(activityid: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/activitybyid/${activityid}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getListingActivity(): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/activity/savedlisting/customer`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }



  deleteActivity(activityid: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/activity/${activityid}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getRecentFeedActivities() {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/activity/savedlisting/customer`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }
  getRcentFeedsByListingId(listingId: string){
    return this.httpClient
    .get<any>(
      `${environment.apiUrl}/publicUri/activity/listing?listingId=${listingId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  filterRecentFeedsByDate(fromDate: any, toDate: any ): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/activity/savedlisting/customer?fromDate=${fromDate}&toDate=${toDate}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }
}
