import { Component, EventEmitter, Inject, Output, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GalleryService } from 'src/app/_services/gallery.services';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tagged-member-dialog',
  templateUrl: './tagged-member-dialog.component.html',
  styleUrl: './tagged-member-dialog.component.css'
})
export class TaggedMemberDialogComponent {

  @ViewChild('callDenyRequestDialog')
  callDenyRequestDialog: TemplateRef<any>;

  tagReqData: any;
  filterData: any;
  taggedMemberData: any;
  taggedData: any;
  @Output() taggedImageUpdated = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  private galleryService: GalleryService,
  private router: Router,
  public dialog: MatDialog,
  private snackBar: MatSnackBar
){
  }

  ngOnInit(){
    // console.log(this.data.tagged,'dkfh')
    // this.taggedMemberData = this.data.tagged
    this.taggedImageData();
  }


  taggedImageData(){
    this.galleryService.getBusinessGallery().subscribe((data: any) => {
      if(data && data?.list){
           this.taggedData = data?.list;
           this.filterData = this.taggedData.find((r: any) => r.id === this.data.imageid);
          }
    });
         }



  deniedTagRequest(imageId: any, taggedUserId: any){
    let dialogRef = this.dialog.open(this.callDenyRequestDialog, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        let tagRequestStatus = 'denied'
        this.galleryService.updateRequest(imageId, taggedUserId, tagRequestStatus).subscribe((data: any)=>{
          this.taggedImageData();
        })
      } else {
        if (result === 'no') {

        }
      }
    });
  }

      closeDialog()
      {
        this.taggedImageUpdated.emit();
        this.dialog.closeAll();
      }

}
