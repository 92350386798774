<div class="container-fluid" style="padding-top: 10px">
  <h1><b>Saved</b></h1>
  <div class="row">
    <div
      class="col-md-9"
      style="padding: 10px; display: flex; align-items: flex"
    >
      <mat-chip-list aria-label="Tab Selection">
        <button
          (click)="changeTab('Saved me')"
          [style.color]="selectedTab === 'Saved me' ? '#fff' : '#333333'"
          class="mx-2 mat-stroked-button"
          style="
            border-radius: 30px;
            height: 40px;
            width: 100px;
            cursor: pointer;
          "
          [selected]="selectedTab === 'Saved me'"
          [style.background]="selectedTab === 'Saved me' ? '#333333' : ''"
        >
          Saved me
        </button>
        <button
          (click)="changeTab('Saved by me')"
          [style.color]="selectedTab === 'Saved by me' ? '#fff' : '#333333'"
          style="
            border-radius: 30px;
            height: 40px;
            width: 100px;
            cursor: pointer;
          "
          class="mat-stroked-button"
          [selected]="selectedTab === 'Saved by me'"
          [style.background]="selectedTab === 'Saved by me' ? '#333333' : ''"
        >
          Saved by me
        </button>
      </mat-chip-list>
    </div>
    <div class="col-md-3">
      <mat-form-field
        style="float: right"
        appearance="outline"
        class="custom-form-field custom-field displaySearch"
        appearance="outline"
      >
        <input
          matInput
          placeholder="Search"
          (keyup)="onSearch($event)"
          [(ngModel)]="searchQuery"
          class="example-form-field example-full-width custom-field search-box"
        />
        <button
          matSuffix
          mat-icon-button
          class="mat-icon-button"
          aria-label="Clear"
          (click)="clearFilter()"
          *ngIf="searchQuery !== ''"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          matSuffix
          mat-icon-button
          class="mat-icon-button"
          aria-label="Clear"
          (click)="onSearch(searchQuery)"
          *ngIf="searchQuery === ''"
        >
          <mat-icon *ngIf="searchQuery === ''" style="margin-bottom: 20px"
            >search</mat-icon
          >
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="selectedTab === 'Saved by me'">
    <div
      class="col-xl-4 col-lg-6 col-md-6 col-sm-12"
      *ngFor="let saved of savedbyme; let i = index"
    >
      <mat-card class="card-style" style="margin-bottom: 25px">
        <mat-card-content>
          <div class="row">
            <div class="col-1">
              <!-- style="height: 80px; width: 80px; border-radius: 10px" -->
              <img
                [src]="saved.listingProfileImageUrl"
                style="
                  height: 80px;
                  width: 80px;
                  border-radius: 10px;
                  float: left;
                  object-fit: cover;
                "
                *ngIf="saved?.listingProfileImageUrl"
              />
              <!-- style="height: 80px; width: 80px; border-radius: 10px" -->
              <img
                src="../../../assets/images/grey.jpg"
                style="
                  height: 80px;
                  width: 80px;
                  border-radius: 10px;
                  float: left;
                  object-fit: cover;
                "
                *ngIf="!saved?.listingProfileImageUrl"
              />
            </div>
            <div class="col-11 mx-4">
              <h2 class="savedName">
                <b
                  style="cursor: pointer"
                  [routerLink]="[
                    '/',
                    saved.listingUserName
                  ]"
                  >{{ saved.listingName }}</b
                >
                <span
                  *ngIf="selectedTab === 'Saved by me'"
                  class="material-icons"
                  style="
                    float: right;
                    color: hsl(150, 2%, 81%);
                    cursor: pointer;
                  "
                  (click)="deleteSavedByMeListing(saved.id, saved.listingName)"
                >
                  highlight_off
                </span>
              </h2>
              <p style="margin-top: -15px; padding-left: 68px">
                <app-star-rating
                  [rating]="saved.listingReview"
                  [starCount]="starCount"
                  [color]="starColor"
                ></app-star-rating>
              </p>
              <p
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 67px;
                  margin-top: -15px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ saved.listingAddress }}
              </p>
              <p
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 72px;
                  margin-top: -10px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  phone </span
                >{{ saved.listingPhoneNumber }}
              </p>

              <div *ngFor="let saved of savedbyme.listingAmenities">
                {{ saved }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-chip
                style="
                  align-items: center;
                  display: inline;
                  padding: 5px;
                  background-color: #f4f5ff;
                "
                >{{ saved.createdDate | date : "dd-MMM-yy" }}</mat-chip
              >
              <button
                mat-raised-button
                class="sendMessage-btn"
                (click)="savedByMeMessage(saved.id)"
              >
                Send Message
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="selectedTab === 'Saved me'">
    <!-- class="col-xl-4 col-lg-6 col-md-8" -->
    <div
      class="col-xl-4 col-lg-6 col-md-6 col-sm-12"
      *ngFor="let saved of savedme; let i = index"
    >
      <mat-card class="card-style" style="margin-bottom: 25px">
        <mat-card-content>
          <div class="row">
            <!-- class="col-md-2" -->
            <div class="col-1">
              <img
                [src]="saved.listingProfileUrl"
                style="
                  height: 80px;
                  width: 80px;
                  border-radius: 10px;
                  float: left;
                  object-fit: cover;
                "
                *ngIf="saved?.listingProfileUrl"
              />
              <img
                src="../../../assets/images/grey.jpg"
                style="
                  height: 80px;
                  width: 80px;
                  border-radius: 10px;
                  float: left;
                  object-fit: cover;
                "
                *ngIf="!saved?.listingProfileUrl"
              />
            </div>
            <!-- class="col-md-10" -->
            <div class="col-11 mx-4">
              <!-- style="padding-left: 30px; cursor: pointer;" -->
              <h2 class="savedName">
                <b
                  style="cursor: pointer"
                  [routerLink]="['/', saved.listingUserName]"
                  >{{ saved.listingName }}</b
                >
                <span
                  class="material-icons"
                  style="
                    float: right;
                    color: hsl(150, 2%, 81%);
                    cursor: pointer;
                  "
                  (click)="
                    deleteSavedMeListing(saved.savedObjectId, saved.listingName)
                  "
                >
                  highlight_off
                </span>
              </h2>
              <!-- style="margin-top: -26px; padding-left: 15px" -->
              <p style="margin-top: -15px; padding-left: 68px">
                <app-star-rating
                  [rating]="saved.listingReview"
                  [starCount]="starCount"
                  [color]="starColor"
                ></app-star-rating>
              </p>
              <p
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 71px;
                  margin-top: -12px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ saved.listingAddress }}
              </p>
              <p
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 75px;
                  margin-top: -8px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  phone </span
                >{{ saved.listingPhone }}
              </p>

              <div *ngFor="let saved of savedme.listingAmenities">
                {{ saved }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-chip
                style="
                  align-items: center;
                  display: inline;
                  padding: 5px;
                  background-color: #f4f5ff;
                "
                >{{ saved.createdDate | date : "dd-MMM-yy" }}</mat-chip
              >
              <button
                mat-raised-button
                class="sendMessage-btn"
                (click)="sendMessage(saved.listingName, saved.listingId)"
              >
                Send Message
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #callDeleteFavCustomerDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Delete Confirmation !</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px; font: 400 20px;">
        Are you sure you want to delete <b>" {{ deleteSavedListingName }} "</b>?
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
