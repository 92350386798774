<div class="container-fluid" style="padding-top: 10px">
  <h1><b>Reviews</b></h1>
  <div class="row">
    <div
      class="col-lg-6 col-md-12"
      *ngFor="let review of reviews; let i = index"
    >
      <div class="col-md-12">
        <mat-card
          class="screen-display"
          style="cursor: default; border-radius: 20px"
        >
          <div class="review-card-style" style="padding-bottom: 20px">
            <div class="leaderboard-icon-style" style="margin-left: -10px">
              <img
                src="../../../assets/images/grey.jpg"
                alt="Avatar"
                style="width: 80px; height: 80px; border-radius: 10px"
                *ngIf="!review?.listingProfileUrl"
              />
              <img
                src="{{ review?.listingProfileUrl }}"
                alt="Avatar"
                style="width: 80px; height: 80px; border-radius: 10px"
                *ngIf="review?.listingProfileUrl"
              />
            </div>
            <div class="recentReview-text-style">
              <div class="row" style="display: flex; align-items: center">
                <div class="col-sm-10">
                  <h2
                    class="review-title"
                    style="
                      margin-left: 70px;
                      line-height: 1.2px;
                      cursor: pointer;
                      word-break: break-word;
                    "
                  >
                    <b
                      [routerLink]="[
                        '/',
                        review.listingUserName
                      ]"
                      >{{ review.listingName }}</b
                    >
                  </h2>
                </div>
                <div class="col-sm-2 delete-edit">
                  <span
                    class="material-icons-outlined review-icons"
                    (click)="editReview(review.id, review)"
                  >
                    edit </span
                  >&nbsp;&nbsp;<span
                    class="material-icons-outlined review-icons"
                    (click)="deleteReview(review.id, review.listingName)"
                  >
                    delete
                  </span>
                </div>
              </div>
              <p class="locationtext">
                <app-star-rating
                  [rating]="review.overall"
                  [starCount]="starCount"
                  [color]="starColor"
                ></app-star-rating>
              </p>
              <p class="locationtextSize">
                <span
                  class="material-icons-outlined"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ review.listingAddress }}
              </p>
              <p class="textsize">
                <span
                  class="material-icons-outlined"
                  style="color: #cdcfce; font-size: 25px"
                >
                  storefront
                </span>
                &nbsp;{{ review.listingName }}
              </p>
              <!-- <p style="display: flex; align-items: center;  margin-top: -15px; font-size: 16px; margin-left: 184px;">{{ review.rating }}</p> -->
              <div class="row" style="display: flex; align-items: center">
                <div class="col-12" style="margin-left: -20px">
                  <h4>
                    <b>"{{ review.reviewTitle }}"</b>
                  </h4>
                  <p [innerHTML]="review.reviewDescription"></p>
                  <!-- <div class="row" style="padding-bottom: 50px;">
                    <div class="col-sm-4" *ngFor="let reviewImageUrls of review.reviewImageUrls">
                    <img src="{{reviewImageUrls}}" style="height: 80px; width: 80px; border-radius: 10px;">
                  </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            style="display: flex; align-items: center; padding: 10px"
          >
            <div class="col-8">
              <mat-chip
                class="date-chip"
                style="
                  align-items: center;
                  display: inline;
                  padding: 5px;
                  background-color: #f4f5ff;
                "
              >
                <span style="color: #525eb1">{{
                  review.createdDate | date : "dd-MMM-yyyy"
                }}</span></mat-chip
              >
              &nbsp;&nbsp;

              <mat-chip *ngIf="review.days > 0" class="reply-chip"
                ><span style="color: #525eb1"
                  >Replied {{ review.days }} days ago</span
                ></mat-chip
              >

              <mat-chip *ngIf="review.days <= 0" class="reply-chip"
                ><span style="color: #525eb1">Replied Today</span></mat-chip
              >
            </div>
            <div class="col-4">
              <span class="edit">
                <button
                  type="button"
                  mat-raised-button
                  class="read-reviews-button mx-4"
                  [routerLink]="['/s/pages/create-reviews', review.id]"
                >
                  Read Reviews
                </button>
              </span>
              <span>
                <!-- <button
                (click)="removeReviews(review._id, i)"
                  type="button"
                  mat-raised-button
                  color="primary"
                  class="mx-4"
                  style="float: right"
                >
                  Delete Reviews
                </button> -->
              </span>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<ng-template #callDeleteGalleryDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Delete Confirmation !</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px; font: 400 20px;">
        Are you sure you want to delete
        <b>" {{ deleteReviewListingName }} "</b> review?
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
