import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IPromotions } from '../_models/promotion.model';
import { ResponseModal } from '../_models/response-modal';

@Injectable({
  providedIn: 'root',
})
export class SavedService {
  constructor(private httpClient: HttpClient) {}

  //getAll Promotions
  getFavorite() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/favoritelistings/customer`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getFavoriteSavedMe() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/favoritelistings/savedme`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  //update Promotion
  updatePromotion(id: any, promotionInfo: IPromotions): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/promotions`,
        { promotionInfo, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateStatus(promotionInfo: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/promotions/statusupdate`,
        promotionInfo,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getPromotionByStatus(businessId: any, selectedTab?: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/promotionsbybusiness/${businessId}?status=${selectedTab}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getProvider(businessId: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/businessproviderbyId/${businessId}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  //delete
  deleteFavoriteListing(favlistingId: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/favoritelistings/${favlistingId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deletesavedmeListing(favlistingId: any) {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/favoritecustomers/${favlistingId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
  customerSavedMeByid(listingId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/favoritemembers/savedme?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  memberSavedMeCountByid(listingId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/publicUri/favoritemembers/savedme/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  customerSavedMeMessage(tabWord: any) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedme?tabWord=${tabWord}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  // checkListingSaved(listingId: string){
  //   return this.httpClient
  //   .get<any>(`${environment.apiUrl}/favoritelistings/existingcheck?listingId=${listingId}`)
  //   .pipe(
  //     map((response) => {
  //       return response || {};
  //     }),
  //     catchError(this.errorMgmt)
  //   );
  // }

  getFavoriteListings(): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/favoritelistings/customer`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  sendMessageTo(obj: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/message/compose`, obj, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  customerSavedMe() {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/favoritecustomers/savedme`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }
  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
