<div class="container-fluid" style="padding-top: 25px">
  <div class="row">
    <h1>Listing Businesses</h1>
    <div
      class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
      *ngFor="let list of listings"
    >
      <mat-card class="card-style">
        <div class="row1">
          <div class="col-sm-3">
            <img
              src="{{ list.profileURL }}"
              style="height: 80px; width: 80px; border-radius: 10px"
            />
          </div>
          <div class="col-sm-9">
            <h2
              style="cursor: pointer; margin-left: 25px"
              (click)="getList(list)"
            >
              {{ list.listingName }}
            </h2>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
