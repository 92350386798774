import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DynamicSearchService {
  constructor(private httpClient: HttpClient) { }



   //create search
   createSearch(searchText: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(`${environment.apiUrl}/publicUri/listings/search?keyword=${searchText}`, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  globalSearch(searchText: string, order: any, pageSize: any, pageIndex: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/publicUri/listings/search?keyword=${searchText}&order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  globalSearchPagination(searchText: string, pageSize: any, pageIndex:any  ): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/publicUri/listings/search?keyword=${searchText}?pageSize=${pageSize}&pageIndex=${pageIndex}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  filterListingsbyindividual(searchText: string, individual: any, order: any, pageSize: any, pageIndex: any){
    return this.httpClient.get<any>(`${environment.apiUrl}/publicUri/listings/search?keyword=${searchText}&listingType=${individual}&order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`).pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  filterListingsbybusiness(searchText: string, business: any, order: any, pageSize: any, pageIndex: any){
    return this.httpClient.get<any>(`${environment.apiUrl}/publicUri/listings/search?keyword=${searchText}&listingType=${business}&order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`).pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }


  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
