<div class="container-fluid">
  <div class="card-orview">
    <button
      class="routerLink"
      [routerLink]="['/s/pages/reviews']"
      routerLinkActive="active"
    >
      <mat-icon>chevron_left</mat-icon>
      <h1 class="mb-0 txt-decor">{{ reviewData?.listingName }}'s Review</h1>
    </button>
  </div>
  <mat-card class="card-style" style="padding: 10px; border-radius: 20px">
    <div class="row" style="padding: 10px">
      <div class="col-sm-2">
        <img
          src="../../../assets/images/grey.jpg"
          alt="Avatar"
          style="height: 120px; width: 120px; border-radius: 10px"
          *ngIf="!reviewData?.listingProfileUrl"
        />
        <img
          src="{{ reviewData?.listingProfileUrl }}"
          alt="Avatar"
          style="height: 120px; width: 120px; border-radius: 10px"
          *ngIf="reviewData?.listingProfileUrl"
        />

        <div class="my-2">
          <!-- <h1>{{reviewData?.customerUserName}}</h1> -->
          <p style="display: flex; align-items: center; color: #7d7d7d">
            <mat-icon class="material-icons-outlined"> storefront </mat-icon
            >&nbsp;{{ reviewData?.listingName }}
          </p>
          <p style="display: flex; align-items: center; color: #7d7d7d">
            <mat-icon
              class="material-icons-outlined"
              *ngIf="listingData?.cityName !== '' && listingData?.state !== ''"
            >
              location_on </mat-icon
            >&nbsp;{{ listingData?.cityName }},&nbsp;{{ listingData?.state }}
          </p>
        </div>
      </div>
      <div class="col-sm-6" style="padding-left: 15px; padding-right: 15px">
        <h2>"{{ reviewData?.reviewTitle }}"</h2>
        <p [innerHTML]="formattedReviewDescription"></p>
        <div class="row" style="padding-bottom: 20px">
          <div class="review-images d-flex flex-wrap">
            <div
              class="review-image-container"
              *ngFor="let reviewImageUrls of reviewData?.reviewImageUrls"
            >
              <img
                [src]="reviewImageUrls"
                alt="Review Image"
                class="review-image"
                style="width: 100px; height: 100px"
              />
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-sm-2" *ngFor="let reviewImageUrls of reviewData?.reviewImageUrls">
          <img src="{{reviewImageUrls}}" style="height: 80px; width: 80px; border-radius: 10px;">
        </div>
        </div> -->
        <div class="row">
          <div class="col-sm-6" style="margin-top: 20px">
            <span
              class="material-icons-outlined"
              style="cursor: pointer"
              (click)="editReview(reviewData.id, reviewData)"
            >
              edit </span
            >&nbsp;&nbsp;
            <span
              class="material-icons-outlined"
              style="cursor: pointer"
              (click)="deleteReview(reviewData.id)"
            >
              delete
            </span>
          </div>
          <div class="col-sm-6" style="margin-top: 15px">
            <mat-chip
              style="
                align-items: center;
                display: inline;
                float: right;
                padding: 5px;
                background-color: #f4f5ff;
              "
              >{{ reviewData?.createdDate | date : "dd-MMM-yyyy" }}</mat-chip
            >
          </div>
        </div>
      </div>
      <div class="col-3">
        <app-review-overall-star
          [rating]="reviewData?.overall"
          [starCount]="starCount"
          [color]="starColor"
          [isReviewShow]="true"
          [reviews]="reviewData?.Overall"
        ></app-review-overall-star>
        <mat-divider></mat-divider>
        <p class="rating-review">
          <span class="reviwscss">Punctuality</span>
          <app-star-rating
            [rating]="reviewData?.punctuality"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p class="rating-review">
          <span class="reviwscss">Service</span>
          <app-star-rating
            [rating]="reviewData?.service"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p class="rating-review">
          <span class="reviwscss">Quality</span>
          <app-star-rating
            [rating]="reviewData?.quality"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p class="rating-review">
          <span class="reviwscss">Price</span>
          <app-star-rating
            [rating]="reviewData?.price"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p class="rating-review">
          <span class="reviwscss">Overall</span>
          <app-star-rating
            [rating]="reviewData?.overall"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-2" *ngIf="reviewData && reviewDetails">
      <div class="col-2" style="text-align: center">
        <img
          src="../../../assets/images/grey.jpg"
          alt="Avatar"
          style="height: 40px; width: 40px; border-radius: 30px"
          *ngIf="!reviewData?.listingProfileUrl"
        />
        <img
          src="{{ reviewData?.listingProfileUrl }}"
          alt="Avatar"
          style="height: 40px; width: 40px; border-radius: 30px"
          *ngIf="reviewData?.listingProfileUrl"
        />
      </div>
      <div class="col-10" *ngIf="reviewData && reviewDetails">
        <p>
          <b>{{ reviewData?.listingName }} Reply</b>
        </p>
        <p>{{ formattedReplyContent }}</p>
      </div>
    </div>
  </mat-card>
</div>

<ng-template #callDeleteGalleryDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Delete Confirmation !</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px; font: 400 20px;">
        Are you sure you want to delete this review?
      </p></mat-dialog-content
    >

    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
