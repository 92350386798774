<div class="container-fluid activity">
  <div class="card-orview">
    <button
      class="routerLink"
      [routerLink]="['/s/pages/dashboard']"
      routerLinkActive="active"
    >
      <mat-icon>chevron_left</mat-icon>
      <h1 class="mb-0 pt-2">Promotions</h1>
    </button>

    <div class="row" style="display: flex; align-items: center">
      <div class="col-md-10" style="padding: 10px; margin-top: 50px">
        <mat-form-field class="field-style custom-field" appearance="outline">
          <mat-select
            (selectionChange)="findPromotionByListing($event)"
            placeholder="All Business Listings"
          >
            <mat-option (click)="showAll()">All Business Listings</mat-option>
            <mat-option
              *ngFor="let list of savedListingNames"
              [value]="list.savedListingId"
              >{{ list.listingName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- <div *ngIf="isLoading" style="margin: 0 auto; justify-content: center; align-items: center; display: flex;">
        <mat-spinner></mat-spinner>
      </div> -->
    <div *ngIf="!isLoading">
      <div class="row" *ngIf="galleryColumns">
        <ng-container *ngFor="let column of galleryColumns">
          <div class="col-lg-3">
            <mat-card
              class="card-style"
              *ngFor="let gall of column; let i = index"
            >
              <img
                src="{{ gall.imagePromotionURL }}"
                alt="Image"
                class="img-fluid rounded-top"
                *ngIf="gall.imagePromotionURL"
              />

              <div class="p-3">
                <p>
                  <b>{{ gall.promotionTitle }}</b>
                </p>
                <p *ngIf="gall.dollar === true && gall.percentage === false">
                  Discount Value: ${{ gall.discountValue }}
                </p>
                <p *ngIf="gall.dollar === false && gall.percentage === true">
                  Discount Value: {{ gall.discountValue }}%
                </p>
                <span class="date">
                  {{ gall.startDateTime | date : "dd-MMM-yyyy" }}&nbsp;To&nbsp;
                  {{ gall.endDateTime | date : "dd-MMM-yyyy" }}
                </span>

                <!-- <p>{{gall.details}}</p> -->
                <br />
                <a
                  *ngIf="gall.details"
                  (click)="
                    ReadMorePromotionDetailsDialog(
                      gall.details,
                      gall.promotionTitle
                    )
                  "
                  style="cursor: pointer"
                >
                  Read More
                </a>
              </div>
              <!-- <hr> -->
              <div class="card-footer">
                <span class="mdc-button__label">
                  <img
                    class="image-style"
                    [src]="gall?.listingProfileImageUrl"
                    class="img-fluid"
                    alt="logo"
                    style="height: 30px; width: 30px; border-radius: 50px"
                  />
                </span>

                <div
                  class="author px-3"
                  style="font-size: 12px; margin-left: -10px"
                >
                  <b>{{ gall.listingName }}</b>
                </div>
              </div>
            </mat-card>
          </div>
          <!-- </div> -->
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #callReadMorePromotionDetailsDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="
        text-align: left;
        font-size: 20px;
        padding: 20px;
        margin-top: -20px;
      "
    >
      <b>{{ promotionTitleParamValue }}</b>
    </p>
    <button
      mat-mini-fab
      mat-dialog-close
      style="
        float: right;
        margin-top: -70px;
        margin-right: 10px;
        background-color: #fff;
        color: #333333;
      "
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-divider style="margin-top: -20px"></mat-divider>

    <mat-dialog-content>
      <p style="padding: 20px; font: 400 20px;">
        {{ promotionDetailsParamValue }}
      </p>
    </mat-dialog-content>
  </form>
</ng-template>
